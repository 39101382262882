import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useCurrentEnvironment({ ticker }) {
  return useQuery(
    ["currentEnvironment", ticker],
    () =>
      fetchCurrentEnvironment({
        ticker
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: ticker != null,
      staleTime: 1000 * 60 * 1,
      refetchInterval: 1000 * 60 * 1
    }
  )
}

const fetchCurrentEnvironment = async ({ ticker }) => {
  const { data } = await axios.get("/api/orats/datav2/current-environment", {
    params: { ticker }
  })

  data.entryIvPct = data.ivPct
  data.entryRsi = data.rsi
  data.entrySlopePct = data.slpPct
  data.entrySma = data.sma
  data.entryVix = data.vix

  delete data.ticker
  delete data.ivPct
  delete data.rsi
  delete data.slpPct
  delete data.sma
  delete data.vix

  return data
}
