import { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleInfo,
  faCircleNotch,
  fa2,
  faEdit
} from "@fortawesome/pro-solid-svg-icons"
import { Dialog, DialogPanel } from "@headlessui/react"
import Tooltip from "./Tooltip"
import BacktestDetails from "./BacktestDetails"
import ShowLevel from "./ShowLevel"
import {
  performanceColumns,
  formatPerformanceMetric,
  getLevel,
  round2,
  DTE,
  Strikes,
  defaultBacktests
} from "./Shared.js"
import { useRouter } from "next/navigation"
import useUserBacktests from "/hooks/useUserBacktests"
import { useQueryClient } from "react-query"
import { useAtom } from "jotai"
import {
  cbSymbolAtom,
  cbBacktestAtom,
  cbSubmittedAtom,
  userIdAtom
} from "/lib/store"
import cloneDeep from "lodash.clonedeep"
import { format } from "date-fns"

export default function CustomBacktestsTable() {
  const queryClient = useQueryClient()
  const [userId, setUserId] = useAtom(userIdAtom)
  const [submitted, setSubmitted] = useAtom(cbSubmittedAtom)
  const [backtests, setBacktests] = useState(null)
  const { data: userBacktests, isFetching: isFetchingUserBacktests } =
    useUserBacktests({
      userId: userId,
      submitted: submitted
    })
  const [symbol, setSymbol] = useAtom(cbSymbolAtom)
  const [backtest, setBacktest] = useAtom(cbBacktestAtom)
  const router = useRouter()
  const [selectedBacktestId, setSelectedBacktestId] = useState(null)
  const scrollRef = useRef(null)

  // Update URL with id
  useEffect(() => {
    if (selectedBacktestId !== null) {
      router.push("/custom-backtests?id=" + selectedBacktestId)
    } else {
      router.push("/custom-backtests")
    }
  }, [selectedBacktestId])

  // Load from URL
  useEffect(() => {
    if (router.query?.id) {
      setSelectedBacktestId(router.query.id)
    }
  }, [router.query])

  useEffect(() => {
    if (userBacktests) {
      setBacktests(userBacktests)
    }
  }, [userBacktests])

  useEffect(() => {
    queryClient.invalidateQueries(["userBacktests"])
  }, [submitted])

  return (
    <>
      <Dialog
        open={selectedBacktestId !== null}
        onClose={() => setSelectedBacktestId(null)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center bg-slate-900/20 dark:bg-zinc-900/80 md:p-14 p-5 font-medium dark:text-white">
          <DialogPanel className="scrollbar my-14 flex h-full w-full flex-col overflow-scroll rounded-xl bg-white dark:bg-zinc-800 px-6 pt-3 shadow-xl dark:border-2 dark:border-zinc-500">
            <BacktestDetails
              selectedBacktestId={selectedBacktestId}
              setSelectedBacktestId={setSelectedBacktestId}
              custom={true}
            />
          </DialogPanel>
        </div>
      </Dialog>
      <div className="mt-7 flex flex-row flex-wrap items-baseline">
        <div className="mt-2 flex h-6 w-6 flex-none flex-row items-center justify-center rounded-full bg-blue-600 dark:bg-blue-500 text-center text-xs text-white">
          <FontAwesomeIcon icon={fa2} />
        </div>
        <div className="ml-2 mr-4 mt-2 flex-none">My Backtests</div>
        <div className="text-sm">
          Showing {backtests?.length > 0 ? backtests?.length : 0} backtest
          {backtests?.length == 1 ? "" : "s"}
        </div>
      </div>
      <div className="mt-4 max-w-fit">
        <div
          className="scrollbar flex max-h-[32rem] flex-col overflow-y-auto overflow-x-auto rounded-md border border-slate-300 dark:border-zinc-500"
          ref={scrollRef}
        >
          <div className="sticky top-0 z-10 flex flex-row flex-nowrap rounded-tl-md rounded-tr-md text-sm">
            <div className="w-12 flex-none border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4"></div>
            <div className="border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 p-4">
              <div className="w-40 text-emerald-600">Date Completed</div>
            </div>
            <div className="border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4">
              <div className="text-orange-600">Backtest Inputs</div>
              <div className="mt-5 flex flex-row">
                <div className="mr-6 w-16">Symbol</div>
                <div className="mr-6 w-32">Strategy</div>
                <div className="mr-6 w-36">Days to Expiration</div>
                <div className="mr-6 w-48">Strike Deltas</div>
                <div className="mr-6 w-36">Spread / Stock %</div>
              </div>
            </div>
            <div className="border-b border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4">
              <div className="text-blue-600 dark:text-blue-500">
                Performance
              </div>
              <div className="flex flex-row">
                {performanceColumns.map(col => (
                  <div
                    className={"mr-6 w-44 " + (col.subtitle ? "mt-1" : "mt-5")}
                    key={col.id}
                  >
                    <div className="truncate">{col.name}</div>
                    {col.subtitle && (
                      <div className="flex flex-row items-center text-xs font-normal italic">
                        <div>{col.subtitle}</div>
                        <Tooltip
                          content={
                            <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal text-white shadow-md">
                              {col.description}
                            </div>
                          }
                        >
                          <FontAwesomeIcon
                            className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                            icon={faCircleInfo}
                            aria-hidden="true"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-grow">
            {backtests === null && isFetchingUserBacktests ? (
              <div className="my-36 flex h-full w-full flex-col items-center justify-center">
                <div className="italic text-slate-600 dark:text-zinc-300">
                  Fetching my custom backtests
                </div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="spinner mt-3 text-2xl text-slate-600 dark:text-zinc-300"
                  aria-hidden="true"
                />
              </div>
            ) : backtests?.length == 0 ? (
              <div
                className="relative my-36 flex h-full w-full flex-col items-center justify-center"
                style={{
                  left: scrollRef.current?.scrollLeft
                }}
              >
                <div className="px-6 italic text-slate-600 dark:text-zinc-300">
                  No custom backtests yet. Start by creating a backtest above.
                </div>
              </div>
            ) : (
              backtests?.map((backtest, index) =>
                backtest.status == "saved" ? (
                  <div
                    key={index}
                    className="group flex cursor-pointer flex-row flex-nowrap text-sm"
                    onClick={() => {
                      setSelectedBacktestId(backtest.id)
                    }}
                  >
                    <div className="flex w-12 flex-none flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      <div
                        className="flex w-12 flex-row items-center text-blue-600 dark:text-blue-500"
                        onClick={e => {
                          e.stopPropagation()
                          let _backtest = cloneDeep(
                            defaultBacktests.find(
                              b => b.id === backtest.strategy
                            )
                          )
                          _backtest.input = backtest.input
                          setBacktest(_backtest)
                          setSymbol(backtest.ticker)
                          document
                            .getElementById("walkthrough-scroll")
                            .scrollTo({
                              top: 0
                            })
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </div>
                    </div>
                    <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      <div className="flex w-40 flex-row items-center">
                        {convertDate(backtest.createdDate)}
                      </div>
                    </div>
                    <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      <div className="mr-6 flex w-16 flex-row items-center">
                        {backtest.ticker}
                      </div>
                      <div className="mr-6 flex w-32 flex-row items-center">
                        {backtest.strategy.replace(/([A-Z])/g, " $1").trim()}
                      </div>
                      <div className="mr-6 flex w-36 flex-row items-center">
                        <DTE backtest={backtest} />
                      </div>
                      <div className="mr-6 flex w-48 flex-row items-center">
                        <Strikes backtest={backtest} />
                      </div>
                      <div className="mr-6 flex w-36 flex-row items-center">
                        {backtest.spreadYieldTarget ? (
                          <div className="flex flex-row items-center">
                            <div>
                              {round2(backtest.spreadYieldTarget * 100)}
                            </div>
                            <ShowLevel level={backtest.spreadYieldLevel} />
                          </div>
                        ) : (
                          <div>None</div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row border-b dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      {performanceColumns.map(col => (
                        <div
                          key={col.id}
                          className="mr-6 flex w-44 flex-row items-center"
                        >
                          {formatPerformanceMetric(col.id, backtest[col.id])}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex flex-row flex-nowrap text-sm"
                  >
                    <div className="flex w-12 flex-none flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800"></div>
                    <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      <div
                        className={
                          "flex w-40 flex-row items-center italic " +
                          (backtest.status == "error"
                            ? " text-red-600"
                            : "animate-pulse text-blue-600 dark:text-blue-500")
                        }
                      >
                        {backtest.status.charAt(0).toUpperCase() +
                          backtest.status.slice(1)}
                      </div>
                    </div>
                    <div className="flex flex-row border-b dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                      <div className="mr-6 flex w-16 flex-row items-center">
                        {backtest.ticker}
                      </div>
                      <div className="mr-6 flex w-32 flex-row items-center">
                        {backtest.strategy.replace(/([A-Z])/g, " $1").trim()}
                      </div>
                      <div className="flex w-[520rem] flex-row "></div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function convertDate(date) {
  let _date = new Date(date)
  const offset = _date.getTimezoneOffset()
  const newDate = new Date(_date.getTime() - offset * 60 * 1000)
  return format(newDate, "MMM d, yyyy h:mm a")
}
