import { useQuery } from "react-query"
import axios from "@/lib/axios"
import { containsAll } from "@/lib/utils"

export default function useUserData({ email }, options = {}) {
  return useQuery(["userData", email], () => fetchUserData(email), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: email != null,
    ...options
  })
}

const fetchUserData = async email => {
  const { data } = await axios.get("/api/orats/autotrade/userdata", {
    params: { email }
  })

  // Modify the data
  for (let i = 0; i < data.signals.length; i++) {
    if (Array.isArray(data.signals[i].criteria) === false) {
      data.signals[i].criteria = [data.signals[i].criteria]
    }
  }

  // Return the data sorted by updated date
  data.signals.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })
  data.stockScans.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })
  data.optionScans.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })

  // Fetch backtest favorites
  const { data: backtestFavorites } = await axios.get(
    "/api/orats/backtests/favorites",
    { params: { email } }
  )
  data.backtestFavorites = backtestFavorites

  // Check if the user has a watchlist, if they don't, create one for them
  const { data: watchlists } = await axios.get(
    "/api/orats/autotrade/userdata/watchlists",
    { params: { email } }
  )
  if (watchlists !== null) {
    data.watchlist = {
      id: watchlists[0].id,
      tickers: watchlists[0].tickers
    }
  } else {
    let tickers = [
      "SPY",
      "QQQ",
      "AAPL",
      "MSFT",
      "GOOG",
      "AMZN",
      "TSLA",
      "UNH",
      "NFLX",
      "META"
    ]
    const { data: newWatchlist } = await axios.post(
      "/api/orats/autotrade/userdata/watchlist",
      {
        email: email,
        name: "Watchlist",
        tickers: tickers
      }
    )
    data.watchlist = {
      id: newWatchlist.id,
      tickers: tickers
    }
  }

  // Get notification settings
  const { data: notificationSettings } = await axios.get(
    "/api/orats/autotrade/userdata/user",
    { params: { email } }
  )
  if (notificationSettings.hasOwnProperty("id")) {
    data.phone = notificationSettings.phone
    // add any newly introduced props here
    data.notificationSettings = { ...notificationSettings.data }
    if (
      !containsAll(Object.keys(data.notificationSettings), [
        "orderFills",
        "orderFillSounds"
      ])
    ) {
      data.notificationSettings = {
        ...data.notificationSettings,
        ...(data.notificationSettings.orderFills == null && {
          orderFills: true
        }),
        ...(data.notificationSettings.orderFillSounds == null && {
          orderFillSounds: true
        })
      }
      await axios.put(
        `/api/orats/autotrade/userdata/user?email=${encodeURIComponent(email)}`,
        {
          email: email,
          phone: data.phone,
          data: data.notificationSettings
        }
      )
    }
  } else {
    let newPhone = ""
    let newNotificationSettings = {
      textExitAlerts: true,
      emailExitAlerts: true,
      webExitAlerts: true,
      orderFills: true,
      orderFillSounds: true
    }
    await axios.post("/api/orats/autotrade/userdata/user", {
      email: email,
      phone: newPhone,
      data: newNotificationSettings
    })
    data.phone = newPhone
    data.notificationSettings = newNotificationSettings
  }

  return data
}
