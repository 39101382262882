import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import cloneDeep from "lodash.clonedeep"
import {
  defaultFilters,
  tickers,
  strategies,
  rankDirs,
  ranks,
  defaultBacktests
} from "/components/Shared"

export const btTickerAtom = atom(tickers[0])
export const btStrategyAtom = atom(strategies.find(s => s.id === "IronCondor"))
let startingFilters = cloneDeep(defaultFilters)
startingFilters.pctOfTimeInMarket.min = 40
startingFilters.marginToStockPct.max = 20
startingFilters.riskRewardAvg.min = 0.07
export const btFiltersAtom = atom(startingFilters)
export const btRankAtom = atom(ranks[2])
export const btRankDirAtom = atom(rankDirs[0])
export const btCurrentEnvironmentToggleAtom = atom(false)

export const cbSymbolAtom = atom("AAPL")
export const cbBacktestAtom = atom(cloneDeep(defaultBacktests[0]))
export const cbSubmittedAtom = atomWithStorage("cbSubmitted", [])

export const userIdAtom = atom(null)
export const themeAtom = atom(null)
export const ibPlatformAtom = atom(null)
export const fontSizeAtom = atom(null)
