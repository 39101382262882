import { useState, useEffect, useRef, Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faHyphen,
  faCircleDot,
  faCircleInfo,
  faCircleNotch
} from "@fortawesome/pro-solid-svg-icons"
import { faCircle } from "@fortawesome/pro-regular-svg-icons"
import cloneDeep from "lodash.clonedeep"
import isEqual from "lodash.isequal"
import { roundWithLetter } from "/lib/utils"
import useStockReturns from "/hooks/useStockReturns"
import useAllBacktests from "/hooks/useAllBacktests"
import useAvailableStrategies from "/hooks/useAvailableStrategies"
import useCurrentEnvironment from "/hooks/useCurrentEnvironment"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition,
  Popover,
  PopoverButton,
  PopoverPanel,
  Dialog,
  DialogPanel,
  Switch
} from "@headlessui/react"
import Tooltip from "./Tooltip"
import BacktestDetails from "./BacktestDetails"
import ShowLevel from "./ShowLevel"
import {
  defaultFilters,
  strategies,
  ranks,
  rankDirs,
  entryTriggers,
  exitTriggers,
  performanceColumns,
  formatPerformanceMetric,
  getLevel,
  round2,
  DTE,
  Strikes
} from "./Shared.js"
import { useAtom } from "jotai"
import {
  btTickerAtom,
  btStrategyAtom,
  btFiltersAtom,
  btRankAtom,
  btRankDirAtom,
  btCurrentEnvironmentToggleAtom
} from "/lib/store"
import { useRouter } from "next/navigation"
import { useSearchParams } from "next/navigation"

export default function AllBacktests() {
  const router = useRouter()
  const searchParams = useSearchParams()

  const [ticker, setTicker] = useAtom(btTickerAtom)
  const [strategy, setStrategy] = useAtom(btStrategyAtom)
  const [filters, setFilters] = useAtom(btFiltersAtom)
  const [rank, setRank] = useAtom(btRankAtom)
  const [rankDir, setRankDir] = useAtom(btRankDirAtom)
  const [currentEnvironmentToggle, setCurrentEnvironmentToggle] = useAtom(
    btCurrentEnvironmentToggleAtom
  )
  const [numEntryTriggers, setNumEntryTriggers] = useState(0)
  const [numExitTriggers, setNumExitTriggers] = useState(0)
  const [selectedBacktestId, setSelectedBacktestId] = useState(null)
  const scrollRef = useRef(null)

  const { data: availableStrategies } = useAvailableStrategies()

  useStockReturns({
    ticker
  })
  const { data: currentEnvironment } = useCurrentEnvironment({
    ticker
  })

  const { data: allBacktests, isFetching: isFetchingAllBacktests } =
    useAllBacktests({
      ticker,
      strategy,
      currentEnvironment: currentEnvironmentToggle
        ? currentEnvironment
        : "noCurrentEnvironment",
      filters,
      rank,
      rankDir
    })

  useEffect(() => {
    // Calculate number of entry triggers
    let _numEntryTriggers = 0
    if (currentEnvironmentToggle) {
      _numEntryTriggers++
    } else {
      if (filters.entryVix !== null) {
        _numEntryTriggers++
      }
      if (filters.entrySma !== null) {
        _numEntryTriggers++
      }
      if (filters.entryRsi !== null) {
        _numEntryTriggers++
      }
      if (filters.entryIvPct !== null) {
        _numEntryTriggers++
      }
      if (filters.entrySlopePct !== null) {
        _numEntryTriggers++
      }
    }
    setNumEntryTriggers(_numEntryTriggers)

    // Calculate number of exit triggers
    let _numExitTriggers = 0
    if (filters.exitStopLoss !== null) {
      _numExitTriggers++
    }
    if (filters.exitProfit !== null) {
      _numExitTriggers++
    }
    setNumExitTriggers(_numExitTriggers)
  }, [filters, currentEnvironmentToggle])

  // Update URL with id
  useEffect(() => {
    if (selectedBacktestId !== null) {
      router.push("/all-backtests?id=" + selectedBacktestId)
    } else {
      router.push("/all-backtests")
    }
  }, [selectedBacktestId])

  // Load from URL
  useEffect(() => {
    if (searchParams.has("id")) {
      setSelectedBacktestId(searchParams.get("id"))
    }
  }, [searchParams])

  return (
    <>
      <Dialog
        open={selectedBacktestId !== null}
        onClose={() => setSelectedBacktestId(null)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center bg-slate-900/20 dark:bg-zinc-900/80 md:p-14 p-5 font-medium dark:text-white">
          <DialogPanel className="scrollbar my-14 flex h-full w-full flex-col overflow-scroll rounded-xl bg-white dark:bg-zinc-800 px-6 pt-3 shadow-xl dark:border-2 dark:border-zinc-500">
            <BacktestDetails
              selectedBacktestId={selectedBacktestId}
              setSelectedBacktestId={setSelectedBacktestId}
              custom={false}
            />
          </DialogPanel>
        </div>
      </Dialog>
      <div className="min-h-0 flex flex-col">
        <div className="min-h-fit md:min-h-0 flex flex-col">
          <div className="pt-5 px-3">
            <div className="flex flex-row flex-wrap items-center text-sm">
              <div className="mb-2 mr-2">
                Showing the top{" "}
                {allBacktests?.length > 0 ? allBacktests.length : 0} of{" "}
                {availableStrategies &&
                  roundWithLetter(
                    availableStrategies[ticker]?.find(
                      s => s.strategy === strategy.id
                    )?.count,
                    false
                  )}{" "}
                backtest
                {allBacktests?.length == 1 ? "" : "s"} for
              </div>
              <div className="relative z-40 mb-2 mr-2">
                <Listbox value={ticker} onChange={setTicker} as="div">
                  <ListboxButton className="flex w-24 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                    {ticker}
                    <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
                  </ListboxButton>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-24 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                      {Object.keys(availableStrategies || {}).map(ticker => (
                        <ListboxOption
                          key={ticker}
                          value={ticker}
                          as={Fragment}
                        >
                          {({ active, selected }) => (
                            <div
                              className={
                                "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                (active
                                  ? " bg-slate-200/70 dark:bg-zinc-700"
                                  : "")
                              }
                            >
                              {ticker}
                            </div>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
              <div className="relative z-30 mb-2 mr-2">
                <Listbox
                  value={strategy}
                  onChange={s => {
                    setStrategy(s)

                    // Clear dte and strikes filter when strategy changes
                    let _filters = cloneDeep(filters)
                    _filters.dte1Target = { min: null, max: null }
                    _filters.dte2Target = { min: null, max: null }
                    _filters.dte3Target = { min: null, max: null }
                    _filters.dte4Target = { min: null, max: null }
                    _filters.delta1Target = { min: null, max: null }
                    _filters.delta2Target = { min: null, max: null }
                    _filters.delta3Target = { min: null, max: null }
                    _filters.delta4Target = { min: null, max: null }
                    setFilters(_filters)
                  }}
                  as="div"
                >
                  <ListboxButton className="flex w-60 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                    {strategy.name}
                    <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
                  </ListboxButton>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-full overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                      {availableStrategies &&
                        availableStrategies[ticker]?.map(strategy => (
                          <ListboxOption
                            key={strategy.strategy}
                            value={strategies.find(
                              s => s.id === strategy.strategy
                            )}
                            as={Fragment}
                          >
                            {({ active, selected }) => (
                              <div
                                className={
                                  "flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                  (active
                                    ? " bg-slate-200/70 dark:bg-zinc-700"
                                    : "")
                                }
                              >
                                <div>
                                  {
                                    strategies.find(
                                      s => s.id === strategy.strategy
                                    )?.name
                                  }
                                </div>
                                <div className="ml-2 rounded-full bg-slate-100 px-2 py-1 text-xs text-slate-700 dark:bg-zinc-200 dark:text-zinc-700">
                                  {roundWithLetter(strategy.count, false)}
                                </div>
                              </div>
                            )}
                          </ListboxOption>
                        ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
              <div className="mb-2 mr-2">ranked by </div>
              <div className="relative z-20 mb-2 mr-2">
                <Listbox value={rank} onChange={setRank} as="div">
                  <ListboxButton className="flex w-64 sm:w-80 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                    {rank.name}
                    <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
                  </ListboxButton>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-64 sm:w-80 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                      <>
                        {ranks.slice(0, 3).map(rank => (
                          <ListboxOption
                            key={rank.id}
                            value={rank}
                            as={Fragment}
                          >
                            {({ active, selected }) => (
                              <div
                                className={
                                  "flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                  (active
                                    ? " bg-slate-200/70 dark:bg-zinc-700"
                                    : "")
                                }
                              >
                                <div>{rank.name}</div>
                                <Tooltip
                                  content={
                                    <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal italic text-white shadow-md">
                                      {rank.description}
                                    </div>
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                                    icon={faCircleInfo}
                                    aria-hidden="true"
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </ListboxOption>
                        ))}
                        <div className="mx-2 my-1 h-px rounded-full bg-slate-200 dark:bg-zinc-600"></div>
                        {ranks.slice(3).map(rank => (
                          <ListboxOption
                            key={rank.name}
                            value={rank}
                            as={Fragment}
                          >
                            {({ active, selected }) => (
                              <div
                                className={
                                  "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                  (active
                                    ? " bg-slate-200/70 dark:bg-zinc-700"
                                    : "")
                                }
                              >
                                {rank.name}
                              </div>
                            )}
                          </ListboxOption>
                        ))}
                      </>
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
              <div className="relative z-[15] mb-2 mr-2">
                <Listbox value={rankDir} onChange={setRankDir} as="div">
                  <ListboxButton className="flex w-32 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                    {rankDir.name}
                    <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
                  </ListboxButton>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-32 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                      {rankDirs.map(rankDir => (
                        <ListboxOption
                          key={rankDir.id}
                          value={rankDir}
                          as={Fragment}
                        >
                          {({ active, selected }) => (
                            <div
                              className={
                                "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                (active
                                  ? " bg-slate-200/70 dark:bg-zinc-700"
                                  : "")
                              }
                            >
                              {rankDir.name}
                            </div>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
              <div className="mb-2 mr-2">
                meeting{" "}
                {numOfFilters(filters, numEntryTriggers, numExitTriggers)}
                <span
                  className="ml-2 cursor-pointer text-orange-600 underline"
                  onClick={() => {
                    setFilters(cloneDeep(defaultFilters))
                    setCurrentEnvironmentToggle(false)
                  }}
                >
                  clear filters
                </span>
              </div>
            </div>
          </div>
          <div className="min-h-[28rem] max-w-fit px-3 pb-6 pt-2">
            <div
              className="scrollbar flex h-full flex-col overflow-y-auto overflow-x-auto rounded-md border border-slate-300 dark:border-zinc-500"
              ref={scrollRef}
            >
              <div className="sticky top-0 z-10 flex flex-row flex-nowrap rounded-tl-md rounded-tr-md text-sm">
                <div className="border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 p-4">
                  <div className="w-12 text-emerald-600">Rank</div>
                </div>
                <div className="border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 p-4">
                  <div className="text-orange-600">
                    Backtest Inputs
                    <span className="ml-4 italic text-slate-700">
                      {checkForLegRelationshipFilters(filters)}
                    </span>
                  </div>
                  <div className="mt-5 flex flex-row">
                    <div className="mr-6 w-48">
                      <div>Days to Expiration</div>
                      <MultiLegMinMaxFilter
                        strategy={strategy}
                        filterKey={"dte"}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </div>
                    <div className="mr-6 w-48">
                      <div>Strike Deltas</div>
                      <MultiLegMinMaxFilter
                        strategy={strategy}
                        filterKey={"delta"}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </div>
                    <div className="mr-6 w-36">
                      <div>Spread / Stock %</div>
                      <LevelFilter
                        filterKey={"spreadYieldLevel"}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </div>
                    <div className="w-52">
                      <div>Entry Triggers</div>
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <PopoverButton
                              className={
                                "mt-2 flex w-36 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-xs font-medium ring-0 focus:outline-none focus:ring-0"
                              }
                            >
                              {currentEnvironmentToggle
                                ? "Current env."
                                : numEntryTriggers + " selected"}
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="ml-auto"
                              />
                            </PopoverButton>

                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <PopoverPanel className="absolute z-10 mt-1.5 w-[54rem] rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 p-4 shadow-lg">
                                <div className="flex flex-row items-center">
                                  <div className="text-xs text-slate-600 dark:text-zinc-300">
                                    Select up to 2 entry triggers at a time or
                                  </div>
                                  <Switch
                                    checked={currentEnvironmentToggle}
                                    onChange={setCurrentEnvironmentToggle}
                                    className={`${
                                      currentEnvironmentToggle
                                        ? "bg-orange-600"
                                        : "bg-slate-300"
                                    } relative ml-4 inline-flex h-6 w-11 items-center rounded-full`}
                                  >
                                    <span
                                      className={`${
                                        currentEnvironmentToggle
                                          ? "translate-x-6"
                                          : "translate-x-1"
                                      } inline-block h-4 w-4 transform rounded-full bg-white dark:bg-zinc-800 transition`}
                                    />
                                  </Switch>
                                  <div className="ml-1.5 text-xs text-slate-600 dark:text-zinc-300">
                                    Select each current environment of {ticker}
                                  </div>
                                  <div className="ml-1.5 flex h-4 w-4 flex-row items-center justify-center rounded-full bg-orange-600 text-xs text-white">
                                    C
                                  </div>
                                  <div
                                    className="ml-8 cursor-pointer text-xs text-orange-600 underline"
                                    onClick={() => {
                                      let _filters = cloneDeep(filters)
                                      for (
                                        let i = 0;
                                        i < entryTriggers.length;
                                        i++
                                      ) {
                                        _filters[entryTriggers[i].id] = null
                                      }
                                      setFilters(_filters)
                                    }}
                                  >
                                    Select all
                                  </div>
                                  <div
                                    className="ml-4 cursor-pointer text-xs text-orange-600 underline"
                                    onClick={() => {
                                      let _filters = cloneDeep(filters)
                                      for (
                                        let i = 0;
                                        i < entryTriggers.length;
                                        i++
                                      ) {
                                        _filters[entryTriggers[i].id] = "none"
                                      }
                                      setFilters(_filters)
                                    }}
                                  >
                                    Select none
                                  </div>
                                </div>
                                <div className="mt-2.5 flex flex-row space-x-10">
                                  {entryTriggers.map(trigger => (
                                    <div key={trigger.id}>
                                      <div className="select-none font-semibold">
                                        {trigger.name}
                                      </div>
                                      <div
                                        className={
                                          "mt-1.5 text-xs" +
                                          (currentEnvironmentToggle
                                            ? " opacity-50"
                                            : "")
                                        }
                                      >
                                        <div
                                          className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                          onClick={() => {
                                            if (
                                              currentEnvironmentToggle == false
                                            ) {
                                              let _filters = cloneDeep(filters)
                                              _filters[trigger.id] = null
                                              setFilters(_filters)
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={
                                              filters[trigger.id] == null
                                                ? faCircleDot
                                                : faCircle
                                            }
                                            className="mr-1"
                                          />
                                          <div>All</div>
                                        </div>
                                        <div
                                          className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                          onClick={() => {
                                            if (
                                              currentEnvironmentToggle == false
                                            ) {
                                              let _filters = cloneDeep(filters)
                                              _filters[trigger.id] = "none"
                                              setFilters(_filters)
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={
                                              filters[trigger.id] == "none"
                                                ? faCircleDot
                                                : faCircle
                                            }
                                            className="mr-1"
                                          />
                                          <div>None</div>
                                        </div>
                                        {trigger.levels.map(level => (
                                          <div
                                            className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                            onClick={() => {
                                              if (
                                                currentEnvironmentToggle ==
                                                false
                                              ) {
                                                let _filters =
                                                  cloneDeep(filters)
                                                _filters[trigger.id] = level.id
                                                setFilters(_filters)
                                              }
                                            }}
                                            key={level.id}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                filters[trigger.id] == level.id
                                                  ? faCircleDot
                                                  : faCircle
                                              }
                                              className="mr-1"
                                            />
                                            <div>{level.name}</div>
                                            {currentEnvironment &&
                                              (currentEnvironment[
                                                trigger.id
                                              ].includes(level.id) ||
                                                currentEnvironment[
                                                  trigger.id
                                                ] == trigger.id) && (
                                                <div className="ml-1 flex h-4 w-4 flex-row items-center justify-center rounded-full bg-orange-600 text-xs text-white">
                                                  C
                                                </div>
                                              )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </PopoverPanel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                    <div className="w-36">
                      <div>Exit Triggers</div>
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <PopoverButton
                              className={
                                "mt-2 flex w-36 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-xs font-medium ring-0 focus:outline-none focus:ring-0"
                              }
                            >
                              {numExitTriggers + " selected"}
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="ml-auto"
                              />
                            </PopoverButton>

                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <PopoverPanel className="absolute z-10 mt-1.5 flex w-60 flex-row space-x-10 rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-4 pb-4 pt-3.5 shadow-lg">
                                {exitTriggers.map(trigger => (
                                  <div key={trigger.id}>
                                    <div className="select-none font-semibold">
                                      {trigger.name}
                                    </div>
                                    <div className="mt-1.5 text-xs">
                                      <div
                                        className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                        onClick={() => {
                                          let _filters = cloneDeep(filters)
                                          _filters[trigger.id] = null
                                          setFilters(_filters)
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            filters[trigger.id] == null
                                              ? faCircleDot
                                              : faCircle
                                          }
                                          className="mr-1"
                                        />
                                        <div>All</div>
                                      </div>
                                      <div
                                        className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                        onClick={() => {
                                          let _filters = cloneDeep(filters)
                                          _filters[trigger.id] = "none"
                                          setFilters(_filters)
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            filters[trigger.id] == "none"
                                              ? faCircleDot
                                              : faCircle
                                          }
                                          className="mr-1"
                                        />
                                        <div>None</div>
                                      </div>
                                      {trigger.levels.map(level => (
                                        <div
                                          className="mt-1.5 flex cursor-pointer select-none flex-row items-center"
                                          onClick={() => {
                                            let _filters = cloneDeep(filters)
                                            if (
                                              _filters[trigger.id] == level.id
                                            ) {
                                              _filters[trigger.id] = null
                                            } else {
                                              _filters[trigger.id] = level.id
                                            }
                                            setFilters(_filters)
                                          }}
                                          key={level.id}
                                        >
                                          <FontAwesomeIcon
                                            icon={
                                              filters[trigger.id] == level.id
                                                ? faCircleDot
                                                : faCircle
                                            }
                                            className="mr-1"
                                          />
                                          <div>{level.name}</div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </PopoverPanel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className="border-b border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 p-4">
                  <div className="text-blue-600 dark:text-blue-500">
                    Performance
                  </div>
                  <div className="flex flex-row">
                    {performanceColumns.map(col => (
                      <div
                        className={
                          "mr-6 w-44 " + (col.subtitle ? "mt-1" : "mt-5")
                        }
                        key={col.id}
                      >
                        <div className="truncate">{col.name}</div>
                        {col.subtitle && (
                          <div className="flex flex-row items-center text-xs font-normal italic">
                            <div>{col.subtitle}</div>
                            <Tooltip
                              content={
                                <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal text-white shadow-md">
                                  {col.description}
                                </div>
                              }
                            >
                              <FontAwesomeIcon
                                className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                                icon={faCircleInfo}
                                aria-hidden="true"
                              />
                            </Tooltip>
                          </div>
                        )}
                        <MinMaxFilter
                          filterKey={col.id}
                          filters={filters}
                          setFilters={setFilters}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex-grow">
                {isFetchingAllBacktests ? (
                  <div
                    className="relative h-60 flex w-full flex-col items-center justify-center"
                    style={{
                      left: scrollRef.current?.scrollLeft
                    }}
                  >
                    <div className="px-6 italic text-slate-600 dark:text-zinc-300">
                      Searching{" "}
                      {availableStrategies &&
                        roundWithLetter(
                          availableStrategies[ticker]?.find(
                            s => s.strategy === strategy.id
                          )?.count,
                          false
                        )}{" "}
                      backtests
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="spinner mt-3 text-2xl text-slate-600 dark:text-zinc-300"
                      aria-hidden="true"
                    />
                  </div>
                ) : allBacktests?.length == 0 ? (
                  <div
                    className="relative h-60 flex w-full flex-col items-center justify-center"
                    style={{
                      left: scrollRef.current?.scrollLeft
                    }}
                  >
                    <div className="px-6 italic text-slate-600 dark:text-zinc-300">
                      No backtests available. Please try changing your filters.
                    </div>
                  </div>
                ) : (
                  allBacktests?.map((backtest, index) => (
                    <div
                      key={backtest.id}
                      className="group flex cursor-pointer flex-row flex-nowrap text-sm"
                      onClick={() => {
                        setSelectedBacktestId(backtest.id)
                      }}
                    >
                      <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        <div className="flex w-12 flex-row items-center">
                          {(index + 1).toLocaleString()}
                        </div>
                      </div>
                      <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        <div className="mr-6 flex w-48 flex-row items-center">
                          <DTE backtest={backtest} />
                        </div>
                        <div className="mr-6 flex w-48 flex-row items-center">
                          <Strikes backtest={backtest} />
                        </div>
                        <div className="mr-6 flex w-36 flex-row items-center">
                          {backtest.spreadYieldTarget ? (
                            <div className="flex flex-row items-center">
                              <div>
                                {round2(backtest.spreadYieldTarget * 100)}
                              </div>
                              <ShowLevel level={backtest.spreadYieldLevel} />
                            </div>
                          ) : (
                            <div>None</div>
                          )}
                        </div>
                        <div className="w-52">
                          {backtest.entryVix !== "none" &&
                            backtest.entryVix !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"VIX is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryVix"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entrySma !== "none" &&
                            backtest.entrySma !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"Stock Price is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entrySma"
                                    }) +
                                    " SMA"}
                                </div>
                              </div>
                            )}
                          {backtest.entryRsi !== "none" &&
                            backtest.entryRsi !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"14d RSI is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryRsi"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entryIvPct !== "none" &&
                            backtest.entryIvPct !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"IV Percentile 1y is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryIvPct"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entrySlopePct !== "none" &&
                            backtest.entrySlopePct !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"Slope Percentile 1y is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entrySlopePct"
                                    })}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="w-36">
                          {backtest.exitStopLoss !== "none" &&
                            backtest.exitStopLoss !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {getLevel({
                                    backtest: backtest,
                                    indicator: "exitStopLoss"
                                  }) + " Stop Loss"}
                                </div>
                              </div>
                            )}
                          {backtest.exitProfit !== "none" &&
                            backtest.exitProfit !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {getLevel({
                                    backtest: backtest,
                                    indicator: "exitProfit"
                                  }) + " Profit Target"}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-row border-b dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        {performanceColumns.map(col => (
                          <div
                            key={col.id}
                            className="mr-6 flex w-44 flex-row items-center"
                          >
                            {formatPerformanceMetric(col.id, backtest[col.id])}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function checkForLegRelationshipFilters(filters) {
  if (
    filters.lrDteLeg1Leg2Min.min !== null ||
    filters.lrDteLeg1Leg2Min.max !== null ||
    filters.lrDteLeg2Leg3Min.min !== null ||
    filters.lrDteLeg2Leg3Max.min !== null ||
    filters.lrDteLeg3Leg4Min.min !== null ||
    filters.lrDteLeg3Leg4Max.min !== null ||
    filters.lrSwLeg1Leg2Min.min !== null ||
    filters.lrSwLeg1Leg2Max.min !== null ||
    filters.lrSwLeg2Leg3Min.min !== null ||
    filters.lrSwLeg2Leg3Max.min !== null ||
    filters.lrSwLeg3Leg4Min.min !== null ||
    filters.lrSwLeg3Leg4Max.min !== null
  ) {
    return "Leg relationships are applied as a filter but not shown as a column."
  }
}

function LevelFilter({ filterKey, filters, setFilters }) {
  const levels = [
    {
      name: "All",
      value: null
    },
    {
      name: "None",
      value: "none"
    },
    {
      name: "Low",
      value: "low"
    },
    {
      name: "Moderate",
      value: "moderate"
    },
    {
      name: "High",
      value: "high"
    }
  ]

  const [level, setLevel] = useState(
    levels.find(l => l.value === filters[filterKey])
  )

  useEffect(() => {
    let _level = levels.find(l => l.value === filters[filterKey])
    if (isEqual(level, _level) == false) {
      setLevel(_level)
    }
  }, [filters])

  useEffect(() => {
    let _filters = cloneDeep(filters)
    _filters[filterKey] = level.value
    setFilters(_filters)
  }, [level])

  return (
    <Listbox value={level} onChange={setLevel} as="div">
      <ListboxButton className="mt-2 flex w-32 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-xs font-medium ring-0 focus:outline-none focus:ring-0">
        {level.name}
        <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
      </ListboxButton>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-32 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
          {levels.map(level => (
            <ListboxOption key={level.name} value={level} as={Fragment}>
              {({ active, selected }) => (
                <div
                  className={
                    "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                    (active ? " bg-slate-200/70 dark:bg-zinc-700" : "")
                  }
                >
                  {level.name}
                </div>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Transition>
    </Listbox>
  )
}

function MinMaxFilter({ filterKey, filters, setFilters }) {
  const [minInput, setMinInput] = useState(
    filters[filterKey].min === null ? "" : filters[filterKey].min.toString()
  )
  const [maxInput, setMaxInput] = useState(
    filters[filterKey].max === null ? "" : filters[filterKey].max.toString()
  )
  const [minInputIsValid, setMinInputIsValid] = useState(true)
  const [maxInputIsValid, setMaxInputIsValid] = useState(true)

  useEffect(() => {
    let _minInput =
      filters[filterKey].min === null ? "" : filters[filterKey].min.toString()
    if (isEqual(minInput, _minInput) == false) {
      setMinInput(_minInput)
    }
    let _maxInput =
      filters[filterKey].max === null ? "" : filters[filterKey].max.toString()
    if (isEqual(maxInput, _maxInput) == false) {
      setMaxInput(_maxInput)
    }
  }, [filters, filterKey])

  useEffect(() => {
    if (minInput === "" || minInput === null) {
      setMinInputIsValid(true)
    } else {
      setMinInputIsValid(!isNaN(parseFloat(minInput)))
    }
  }, [minInput])

  useEffect(() => {
    if (maxInput === "" || maxInput === null) {
      setMaxInputIsValid(true)
    } else {
      setMaxInputIsValid(!isNaN(parseFloat(maxInput)))
    }
  }, [maxInput])

  function saveMin() {
    if (minInputIsValid) {
      let parsedFloat = parseFloat(minInput)
      let parsedInput = isNaN(parsedFloat) ? null : parsedFloat
      let _filters = cloneDeep(filters)
      _filters[filterKey].min = parsedInput
      setFilters(_filters)
    }
  }

  function saveMax() {
    if (maxInputIsValid) {
      let parsedFloat = parseFloat(maxInput)
      let parsedInput = isNaN(parsedFloat) ? null : parsedFloat
      let _filters = cloneDeep(filters)
      _filters[filterKey].max = parsedInput
      setFilters(_filters)
    }
  }

  return (
    <div className="mt-2 flex flex-row items-center space-x-1">
      <input
        type="text"
        id="input"
        className={
          "w-14 rounded-md pl-2.5 text-xs font-medium shadow-none outline-none ring-0 focus:outline-none focus:ring-0 " +
          (minInputIsValid
            ? minInput !== ""
              ? "border-blue-600 bg-blue-600/5 focus:border-blue-600 dark:bg-blue-400/20 dark:border-blue-400 dark:focus:border-blue-400"
              : "bg-white border-slate-300 focus:border-slate-300 dark:border-zinc-500 dark:bg-zinc-800"
            : "border-red-500 focus:border-red-500 bg-white dark:bg-zinc-800")
        }
        placeholder="Min"
        value={minInput}
        onChange={e => {
          if (e.target.value !== minInput) {
            setMinInput(e.target.value)
          }
        }}
        onKeyDown={e => {
          if (e.key == "Enter") {
            saveMin()
          }
        }}
        onBlur={() => {
          saveMin()
        }}
        autoComplete="off"
      />
      <FontAwesomeIcon icon={faHyphen} className="h-3 w-3" />
      <input
        type="text"
        id="input"
        className={
          "w-14 rounded-md pl-2.5 text-xs font-medium shadow-none outline-none ring-0 focus:outline-none focus:ring-0 " +
          (maxInputIsValid
            ? maxInput !== ""
              ? "border-blue-600 bg-blue-600/5 focus:border-blue-600 dark:bg-blue-400/20 dark:border-blue-400 dark:focus:border-blue-400"
              : "bg-white border-slate-300 dark:border-zinc-500 focus:border-slate-300 dark:border-zinc-500 dark:bg-zinc-800"
            : "border-red-500 focus:border-red-500 dark:border-red-400 dark:focus:border-red-400 bg-white dark:bg-zinc-800")
        }
        placeholder="Max"
        value={maxInput}
        onChange={e => {
          if (e.target.value !== maxInput) {
            setMaxInput(e.target.value)
          }
        }}
        onKeyDown={e => {
          if (e.key == "Enter") {
            saveMax()
          }
        }}
        onBlur={() => {
          saveMax()
        }}
        autoComplete="off"
      />
    </div>
  )
}

function MultiLegMinMaxFilter({ strategy, filterKey, filters, setFilters }) {
  const [leg, setLeg] = useState(1)

  useEffect(() => {
    setLeg(1)
  }, [strategy])

  function legHasFilter(leg) {
    if (
      filters[filterKey + leg + "Target"].min !== null ||
      filters[filterKey + leg + "Target"].max !== null
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="flex flex-row space-x-1.5">
      <Listbox value={leg} onChange={setLeg} as="div">
        <ListboxButton className="mt-2 flex w-[52px] flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-xs font-medium ring-0 focus:outline-none focus:ring-0">
          {"L" + leg}
          <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
        </ListboxButton>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <ListboxOptions className="scrollbar absolute mt-1.5 w-[52px] overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
            {[1, 2, 3, 4].map(l => (
              <ListboxOption key={l} value={l} as={Fragment}>
                {({ active, selected }) => (
                  <div
                    className={
                      "flex cursor-pointer select-none flex-row items-center space-x-1 rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                      (active ? " bg-slate-200/70 dark:bg-zinc-700" : "")
                    }
                  >
                    {legHasFilter(l) && (
                      <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                    )}
                    <div>{"L" + l}</div>
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
      <MinMaxFilter
        filterKey={filterKey + leg + "Target"}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  )
}

function numOfFilters(filters, numEntryTriggers, numExitTriggers) {
  let count = 0
  for (const [key, value] of Object.entries(filters)) {
    if (value?.hasOwnProperty("min") && value?.hasOwnProperty("max")) {
      if (value.min !== null || value.max !== null) {
        count++
      }
    }
    if (key == "spreadYieldLevel") {
      if (value !== null) {
        count++
      }
    }
  }

  if (numEntryTriggers > 0) {
    count++
  }
  if (numExitTriggers > 0) {
    count++
  }

  if (count > 1) {
    return count + " filters"
  } else if (count === 0) {
    return "no filters"
  } else {
    return count + " filter"
  }
}
