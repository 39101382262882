import { Fragment, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  fa1,
  faChevronDown,
  faArrowDown,
  faArrowUp,
  faArrowsLeftRight,
  faArrowDownLeftAndArrowUpRightToCenter,
  faCircleNotch,
  faCircleInfo
} from "@fortawesome/pro-solid-svg-icons"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition
} from "@headlessui/react"
import { defaultBacktests, symbols } from "./Shared"
import cloneDeep from "lodash.clonedeep"
import Tooltip from "./Tooltip"
import { roundFloat } from "/lib/utils"
import { useQueryClient } from "react-query"
import axios from "@/lib/axios"
import CustomBacktestsTable from "./CustomBacktestsTable"
import { useAtom } from "jotai"
import {
  cbSymbolAtom,
  cbBacktestAtom,
  cbSubmittedAtom,
  userIdAtom,
  themeAtom,
  ibPlatformAtom,
  fontSizeAtom
} from "/lib/store"
import { track } from "/lib/mixpanel"

export default function CustomBacktests() {
  const queryClient = useQueryClient()

  const [userId, setUserId] = useAtom(userIdAtom)
  const [theme, setTheme] = useAtom(themeAtom)
  const [ibPlatform, setIbPlatform] = useAtom(ibPlatformAtom)
  const [fontSize, setFontSize] = useAtom(fontSizeAtom)

  const [submitting, setSubmitting] = useState(false)
  const [symbol, setSymbol] = useAtom(cbSymbolAtom)
  const [backtest, setBacktest] = useAtom(cbBacktestAtom)
  const [submitted, setSubmitted] = useAtom(cbSubmittedAtom)

  useEffect(() => {
    queryClient.invalidateQueries(["userBacktests"])
  }, [submitted])

  const dteOptions = {
    "1w": [7, 5, 9],
    "2w": [14, 10, 18],
    "1m": [30, 20, 45],
    "3m": [90, 40, 120],
    "6m": [180, 135, 225],
    "1y": [365, 275, 450],
    "2y": [730, 550, 900]
  }

  function adjustDte(time) {
    let _backtest = cloneDeep(backtest)
    let legs = _backtest.input.legs
    if (
      _backtest.id == "LongCallCalendar" ||
      _backtest.id == "LongPutCalendar" ||
      _backtest.id == "ShortCallCalendar" ||
      _backtest.id == "ShortPutCalendar"
    ) {
      let keys = Object.keys(dteOptions)
      let nextIndex = keys.indexOf(time) + 1
      let nextTime = keys[nextIndex]
      legs[0].dteTarget = dteOptions[nextTime][0]
      legs[0].dteMin = dteOptions[nextTime][1]
      legs[0].dteMax = dteOptions[nextTime][2]

      legs[1].dteTarget = dteOptions[time][0]
      legs[1].dteMin = dteOptions[time][1]
      legs[1].dteMax = dteOptions[time][2]
    } else {
      for (let i = 0; i < legs.length; i++) {
        legs[i].dteTarget = dteOptions[time][0]
        legs[i].dteMin = dteOptions[time][1]
        legs[i].dteMax = dteOptions[time][2]
      }
    }
    setBacktest(_backtest)
  }

  function adjustStrikes(dir) {
    let _backtest = cloneDeep(backtest)
    let legs = _backtest.input.legs
    let defaultBacktest = null
    if (dir == "reset") {
      defaultBacktest = cloneDeep(defaultBacktests).find(obj => {
        return obj.id === _backtest.id
      })
    }
    for (let i = 0; i < legs.length; i++) {
      if (dir == "lower") {
        legs[i].deltaTarget = Math.min(
          Math.max(roundFloat(legs[i].deltaTarget - 0.05, 2), 0),
          1
        )
        legs[i].deltaMin = Math.min(
          Math.max(roundFloat(legs[i].deltaMin - 0.05, 2), 0),
          1
        )
        legs[i].deltaMax = Math.min(
          Math.max(roundFloat(legs[i].deltaMax - 0.05, 2), 0),
          1
        )
      } else if (dir == "higher") {
        legs[i].deltaTarget = Math.min(
          Math.max(roundFloat(legs[i].deltaTarget + 0.05, 2), 0),
          1
        )
        legs[i].deltaMin = Math.min(
          Math.max(roundFloat(legs[i].deltaMin + 0.05, 2), 0),
          1
        )
        legs[i].deltaMax = Math.min(
          Math.max(roundFloat(legs[i].deltaMax + 0.05, 2), 0),
          1
        )
      } else if (dir == "wider") {
        legs[i].deltaMin = Math.min(
          Math.max(roundFloat(legs[i].deltaMin - 0.05, 2), 0),
          1
        )
        legs[i].deltaMax = Math.min(
          Math.max(roundFloat(legs[i].deltaMax + 0.05, 2), 0),
          1
        )
      } else if (dir == "tighter") {
        let target = legs[i].deltaTarget
        let min = Math.min(
          Math.max(roundFloat(legs[i].deltaMin + 0.05, 2), 0),
          1
        )
        let max = Math.min(
          Math.max(roundFloat(legs[i].deltaMax - 0.05, 2), 0),
          1
        )
        min = Math.min(min, target)
        max = Math.max(max, target)
        legs[i].deltaMin = min
        legs[i].deltaMax = max
      } else {
        legs[i].deltaTarget = defaultBacktest.input.legs[i].deltaTarget

        legs[i].deltaMin = defaultBacktest.input.legs[i].deltaMin

        legs[i].deltaMax = defaultBacktest.input.legs[i].deltaMax
      }
    }

    setBacktest(_backtest)
  }

  const entryCriteria = [
    {
      name: "Spread / Stock",
      description:
        "Spread / stock prices allows you to filter based on the option trade price relative to the stock price. These should be negative for credits and positive for debits. For example, a credit spread might be -0.01 Target, -0.02 Minimum, and -0.005 Maximum, and for a long call might be 0.02 Target, 0.01 Minimum, and 0.03 Maximum.",
      payloadProperty: "spreadYield"
    },
    {
      name: "Strike Diff",
      description:
        "Strike diff allows you to filter based on the option trade price divided by the difference in strike prices.",
      payloadProperty: "strikeDiff"
    },
    {
      name: "DTE Diff",
      description:
        "Control the days to expiration between legs. Most of the time, you would want to set min/max to 0 in order for each leg to have the same expiration date. A Long Call Calendar would be a good example to have min/max set to different ranges so the front month leg would expire earlier than the back month leg. For example, Legs 1 & 2 is calculated as leg 1 dte - leg 2 dte.",
      payloadProperty: "lrDte"
    },
    {
      name: "Strike Width",
      description:
        "Control the strike width between legs. For example, Legs 1 & 2 is calculated as leg 1 strike - leg 2 strike.",
      payloadProperty: "lrSw"
    }
  ]

  return (
    <div className="min-h-0 flex flex-col">
      <div
        className="scrollbar relative z-20 w-full overflow-y-auto pb-6 px-3"
        id="walkthrough-scroll"
      >
        <div className="mt-5 text-sm italic">
          Create and browse your custom backtests using the interface below.
          Choose from 100+ symbols and 11 strategies. Our backtester runs from
          2007 to present on end-of-day options data. It takes anywhere from a
          few minutes to run a basic backtest to tens of minutes to run a four
          legged test.
        </div>
        <div className="mt-3 flex flex-row flex-wrap items-center">
          <div className="mt-2 flex h-6 w-6 flex-none flex-row items-center justify-center rounded-full bg-blue-600 dark:bg-blue-500 text-center text-xs text-white">
            <FontAwesomeIcon icon={fa1} />
          </div>
          <div className="ml-2 mr-4 mt-2 flex-none">Create</div>
          <div className="mr-3 mt-2 flex-none text-sm">Choose symbol</div>
          <Listbox
            value={symbol}
            onChange={setSymbol}
            className="relative z-20 mr-3 mt-2"
            as="div"
          >
            <ListboxButton className="flex-start flex w-24 items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
              <div>{symbol}</div>
              <FontAwesomeIcon className="ml-auto" icon={faChevronDown} />
            </ListboxButton>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-24 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                {symbols.map((symbol, key) => (
                  <ListboxOption key={key} value={symbol} as={Fragment}>
                    {({ active, selected }) => (
                      <div
                        className={
                          "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                          (active ? " bg-slate-200/70 dark:bg-zinc-700" : "")
                        }
                      >
                        {symbol}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </Listbox>
          <div className="mr-3 mt-2 flex-none text-sm">and strategy</div>
          <Listbox
            value={backtest}
            onChange={setBacktest}
            className="relative z-20 mr-3 mt-2"
            as="div"
          >
            <ListboxButton className="flex-start flex w-72 items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-1.5 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
              <div>{backtest.name}</div>
              <div
                className={
                  "ml-2 rounded-full px-3 py-1 text-xs " +
                  (backtest.category == "bullish"
                    ? "bg-emerald-600/10 text-emerald-600"
                    : backtest.category == "bearish"
                      ? "bg-rose-600/10 text-rose-600"
                      : "bg-blue-600/10 text-blue-600")
                }
              >
                {backtest.category.toUpperCase()}
              </div>
              <FontAwesomeIcon className="ml-auto" icon={faChevronDown} />
            </ListboxButton>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <ListboxOptions className="scrollbar absolute mt-1.5 max-h-96 w-72 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                {defaultBacktests?.map(backtest => (
                  <ListboxOption
                    key={backtest.id}
                    value={backtest}
                    as={Fragment}
                  >
                    {({ active, selected }) => (
                      <div
                        className={
                          "flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                          (active ? " bg-slate-200/70 dark:bg-zinc-700" : "")
                        }
                      >
                        <div>{backtest.name}</div>
                        <div
                          className={
                            "ml-2 rounded-full px-3 py-1 text-xs " +
                            (backtest.category == "bullish"
                              ? "bg-emerald-600/10 text-emerald-600"
                              : backtest.category == "bearish"
                                ? "bg-rose-600/10 text-rose-600"
                                : "bg-blue-600/10 text-blue-600")
                          }
                        >
                          {backtest.category.toUpperCase()}
                        </div>
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </Listbox>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="mr-4 mt-4 max-w-fit flex-none rounded-lg border border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 py-4 text-sm">
            <div className="flex flex-row items-center">
              <div>Days to Expiration</div>
              <Tooltip
                content={
                  <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal italic text-white shadow-md">
                    Set the target, min, and max days to expiration for each
                    leg. Click the buttons to quickly change the values.
                  </div>
                }
              >
                <FontAwesomeIcon
                  className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                  icon={faCircleInfo}
                  aria-hidden="true"
                />
              </Tooltip>
            </div>
            <div className="mt-1.5 flex flex-row space-x-2 text-xs text-slate-900 dark:text-zinc-900">
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("1w")
                }}
              >
                1w
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("2w")
                }}
              >
                2w
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("1m")
                }}
              >
                1m
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("3m")
                }}
              >
                3m
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("6m")
                }}
              >
                6m
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustDte("1y")
                }}
              >
                1y
              </div>
            </div>
            <div className="mt-2 flex flex-row space-x-1.5 text-xs text-slate-500 dark:text-zinc-300">
              <div className="w-16">TARGET</div>
              <div className="w-16">MIN</div>
              <div className="w-16">MAX</div>
            </div>
            <div>
              {backtest.input.legs.map((leg, key) => (
                <div
                  key={key}
                  className="mt-1 flex flex-row items-center space-x-1.5"
                >
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "dteTarget"]}
                    allowedNull={false}
                  />
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "dteMin"]}
                    allowedNull={false}
                  />
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "dteMax"]}
                    allowedNull={false}
                  />
                  <div className="text-xs text-slate-500 dark:text-zinc-300">
                    {(leg.ratio > 0 ? "+" : "") +
                      leg.ratio +
                      " " +
                      leg.type.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mr-4 mt-4 max-w-fit flex-none rounded-lg border border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 py-4 text-sm">
            <div className="flex flex-row items-center">
              <div>Strike Deltas</div>
              <Tooltip
                content={
                  <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal italic text-white shadow-md">
                    Set the target, min, and max strikes for each leg. These are
                    represented as absolute deltas. Click the buttons to
                    increase, decrease, tighten, or widen each leg by 5 deltas.
                  </div>
                }
              >
                <FontAwesomeIcon
                  className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                  icon={faCircleInfo}
                  aria-hidden="true"
                />
              </Tooltip>
            </div>
            <div className="mt-1.5 flex flex-row space-x-1.5 text-xs text-slate-900 dark:text-zinc-900">
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustStrikes("lower")
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} className="mr-0.5" />
                5d
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustStrikes("higher")
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} className="mr-0.5" />
                5d
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustStrikes("wider")
                }}
              >
                <FontAwesomeIcon icon={faArrowsLeftRight} className="mr-0.5" />
                5d
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustStrikes("tighter")
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowDownLeftAndArrowUpRightToCenter}
                  className="mr-0.5"
                />
                5d
              </div>
              <div
                className="cursor-pointer select-none rounded-md bg-slate-300/50 px-3 py-1.5 transition hover:bg-slate-400/50 dark:bg-zinc-400 dark:hover:bg-zinc-300"
                onClick={() => {
                  adjustStrikes("reset")
                }}
              >
                Reset
              </div>
            </div>
            <div className="mt-2 flex flex-row space-x-1.5 text-xs text-slate-500 dark:text-zinc-300">
              <div className="w-16">TARGET</div>
              <div className="w-16">MIN</div>
              <div className="w-16">MAX</div>
            </div>
            <div>
              {backtest.input.legs.map((leg, key) => (
                <div
                  key={key}
                  className="mt-1 flex flex-row items-center space-x-1.5"
                >
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "deltaTarget"]}
                    allowedNull={false}
                  />
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "deltaMin"]}
                    allowedNull={false}
                  />
                  <Input
                    backtest={backtest}
                    setBacktest={setBacktest}
                    path={["input", "legs", key, "deltaMax"]}
                    allowedNull={false}
                  />
                  <div className="text-xs text-slate-500 dark:text-zinc-300">
                    {(leg.ratio > 0 ? "+" : "") +
                      leg.ratio +
                      " " +
                      leg.type.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {entryCriteria.map((indicator, key) =>
            (indicator.payloadProperty == "lrDte" ||
              indicator.payloadProperty == "lrSw" ||
              indicator.payloadProperty == "strikeDiff") &&
            backtest.input.legs?.length < 2 ? null : (
              <EntryCriteria
                key={key}
                backtest={backtest}
                setBacktest={setBacktest}
                indicator={indicator}
              />
            )
          )}
        </div>
        <div
          className="mt-6 max-w-fit cursor-pointer rounded-md border border-blue-600 dark:border-blue-500 px-3 py-2 text-blue-600 dark:text-blue-500 hover:bg-blue-600/10 dark:hover:bg-blue-500/10"
          onClick={async () => {
            if (!submitting) {
              setSubmitting(true)
              let result = await axios.post("/api/orats/backtests/custom", {
                userId: userId,
                startDate: "2007-01-03",
                endDate: "2024-05-28",
                input: {
                  ...backtest.input,
                  strategy: backtest.id,
                  ticker: symbol
                }
              })
              setSubmitting(false)
              if (result.data?.id) {
                setSubmitted([
                  ...submitted,
                  {
                    id: result.data?.id,
                    strategy: backtest.id,
                    ticker: symbol
                  }
                ])
                track("Custom backtest created", userId, {
                  id: result.data?.id,
                  strategy: backtest.id,
                  ticker: symbol,
                  theme: theme,
                  ibPlatform: ibPlatform,
                  fontSize: fontSize
                })
              }
            }
          }}
        >
          {submitting ? (
            <>
              <FontAwesomeIcon icon={faCircleNotch} className="spinner mr-2" />{" "}
              Submitting backtest...
            </>
          ) : (
            <>Run backtest</>
          )}
        </div>
        <CustomBacktestsTable />
      </div>
    </div>
  )
}

function EntryCriteria({ backtest, setBacktest, indicator }) {
  return (
    <div className="mr-4 mt-4 max-w-fit flex-none rounded-lg border border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 py-4 text-sm">
      <div className="mb-2.5 flex flex-row items-center">
        <div>{indicator.name}</div>
        <Tooltip
          content={
            <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal italic text-white shadow-md">
              {indicator.description}
            </div>
          }
        >
          <FontAwesomeIcon
            className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
            icon={faCircleInfo}
            aria-hidden="true"
          />
        </Tooltip>
      </div>
      {indicator.name == "Strike Diff" || indicator.name == "Spread / Stock" ? (
        <div className="mt-1">
          <div className="mt-2 flex flex-row space-x-1.5 text-xs text-slate-500 dark:text-zinc-300">
            <div className="w-16">TARGET</div>
            <div className="w-16">MIN</div>
            <div className="w-16">MAX</div>
          </div>
          <div className="mt-1 flex flex-row items-center space-x-1.5">
            <Input
              backtest={backtest}
              setBacktest={setBacktest}
              path={["input", indicator.payloadProperty, "target"]}
              allowedNull={true}
            />
            <Input
              backtest={backtest}
              setBacktest={setBacktest}
              path={["input", indicator.payloadProperty, "min"]}
              allowedNull={true}
            />
            <Input
              backtest={backtest}
              setBacktest={setBacktest}
              path={["input", indicator.payloadProperty, "max"]}
              allowedNull={true}
            />
          </div>
        </div>
      ) : indicator.name == "Strike Width" || indicator.name == "DTE Diff" ? (
        <div className="mt-1">
          <div className="mt-2 flex flex-row space-x-1.5 text-xs text-slate-500 dark:text-zinc-300">
            <div className="w-16">MIN</div>
            <div className="w-16">MAX</div>
          </div>
          {backtest.input.legs.map((leg, key) =>
            key < backtest.input.legs.length - 1 ? (
              <div
                key={key}
                className="mt-1 flex flex-row items-center space-x-1.5"
              >
                <Input
                  backtest={backtest}
                  setBacktest={setBacktest}
                  path={["input", indicator.payloadProperty, key, "min"]}
                  allowedNull={true}
                />
                <Input
                  backtest={backtest}
                  setBacktest={setBacktest}
                  path={["input", indicator.payloadProperty, key, "max"]}
                  allowedNull={true}
                />
                <div className="text-xs text-slate-500 dark:text-zinc-300">
                  {"Legs " + (key + 1) + " and " + (key + 2)}
                </div>
              </div>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  )
}

function Input({ backtest, setBacktest, path, allowedNull }) {
  const [input, setInput] = useState("")
  const [inputIsValid, setInputIsValid] = useState(true)

  useEffect(() => {
    if (allowedNull && (input === "" || input === null)) {
      setInputIsValid(true)
    } else {
      setInputIsValid(!isNaN(parseFloat(input)))
    }
  }, [input])

  useEffect(() => {
    let val = backtest
    for (let i = 0; i < path.length; i++) {
      val = val[parseInt(path[i]) || path[i]]
    }
    if (val === null) {
      setInput("")
    } else {
      setInput(val)
    }
  }, [backtest])

  function save() {
    if (inputIsValid) {
      let parsedFloat = parseFloat(input)
      let parsedInput = isNaN(parsedFloat) ? null : parsedFloat
      let _backtest = cloneDeep(backtest)
      let obj = _backtest
      while (path.length > 1) {
        obj = obj[path.shift()]
      }
      obj[path[0]] = parsedInput
      setBacktest(_backtest)
    }
  }

  return (
    <input
      type="text"
      id="input"
      className={
        "w-16 rounded-md pl-2 text-xs font-medium shadow-none outline-none ring-0 focus:outline-none focus:ring-0 " +
        (inputIsValid
          ? "bg-white border-slate-300 focus:border-slate-300 dark:border-zinc-500 dark:bg-zinc-800"
          : "border-red-500 focus:border-red-500 bg-white dark:bg-zinc-800")
      }
      placeholder=""
      value={input}
      onChange={e => {
        if (e.target.value !== input) {
          setInput(e.target.value)
        }
      }}
      onKeyDown={e => {
        if (e.key == "Enter") {
          save()
        }
      }}
      onBlur={() => {
        save()
      }}
      autoComplete="off"
    />
  )
}
