import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useBacktestPerformance({ id, custom, userId }) {
  return useQuery(
    ["backtestPerformance", id, custom, userId],
    () =>
      fetchBacktestPerformance({
        id,
        custom,
        userId
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )
}

const fetchBacktestPerformance = async ({ id, custom, userId }) => {
  if (custom) {
    const { data } = await axios.get(
      "/api/orats/backtests/custom/performance",
      {
        params: { userId }
      }
    )
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        return data[i]
      }
    }
  } else {
    const { data } = await axios.get("/api/orats/backtests/performance", {
      params: { id }
    })
    return data[0]
  }
}
