import { useState } from "react"
import { useAtom } from "jotai"
import cloneDeep from "lodash.clonedeep"
import { cbSymbolAtom, cbBacktestAtom } from "/lib/store"
import { useRouter } from "next/navigation"
import { usePathname } from "next/navigation"
import { defaultBacktests, symbols } from "./Shared"
import { useEffect } from "react"

export default function EditBacktest({ backtest, setSelectedBacktestId }) {
  const router = useRouter()
  const pathname = usePathname()
  const [cbSymbol, setCbSymbol] = useAtom(cbSymbolAtom)
  const [cbBacktest, setCbBacktest] = useAtom(cbBacktestAtom)
  const [strategyExists, setStrategyExists] = useState(false)
  const [symbolExists, setSymbolExists] = useState(false)

  useEffect(() => {
    setStrategyExists(defaultBacktests.find(b => b.id == backtest?.strategy))
    setSymbolExists(symbols.includes(backtest?.ticker))
  }, [backtest])

  return (
    <div
      className={
        "mr-4 mt-3 flex-none rounded-md border border-blue-600 dark:border-blue-500 px-3 py-2 text-blue-600 dark:text-blue-500 " +
        (strategyExists && symbolExists
          ? "cursor-pointer hover:bg-blue-600/10 dark:hover:bg-blue-500/10"
          : "cursor-not-allowed opacity-50")
      }
      onClick={() => {
        if (strategyExists && symbolExists) {
          let _backtest = cloneDeep(backtest)
          delete _backtest.id
          let _cbBacktest = cloneDeep(
            defaultBacktests.find(b => b.id === _backtest.strategy)
          )
          _cbBacktest.input = _backtest
          setCbBacktest(_cbBacktest)
          setCbSymbol(_backtest.ticker)

          if (pathname.includes("custom-backtests") == false) {
            router.push("/custom-backtests")
          } else {
            setSelectedBacktestId(null)
          }
        }
      }}
    >
      Edit
    </div>
  )
}
