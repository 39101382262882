import { useState, useEffect, Fragment } from "react"
import { formatDate } from "@/lib/utils"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition
} from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { format } from "date-fns"

export default function TradeLog({ data, strategy }) {
  const [allTrades, setAllTrades] = useState([])
  const [filteredTrades, setFilteredTrades] = useState([])
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(months[0])
  const [years, setYears] = useState([])

  useEffect(() => {
    if (data?.length > 0) {
      // Get the years
      let firstYear = new Date(data[0].date).getFullYear()
      let lastYear = new Date(data[data.length - 1].date).getFullYear()
      let _years = []
      for (let i = firstYear; i <= lastYear; i++) {
        _years.push(i)
      }
      setYears(_years)

      // Convert the data into a list of trades
      let _allTrades = []
      for (let i = 0; i < data.length; i++) {
        // Add the opening trade
        _allTrades.push({
          leg: data[i].leg,
          ticker: data[i].ticker,
          type: data[i].optionType,
          strike: data[i].strike,
          entryDate: data[i].date,
          expirDate: data[i].expirDate,
          exitDate: data[i].exitDate,
          stock: data[i].entryStockPx,
          trade: convertTradeToStr(data[i]),
          ratio: data[i].ratio,
          entryPrice: data[i].tradeOptPx,
          exitPrice: data[i].exitOptionPx,
          exitStock: data[i].exitStockPx,
          profit: data[i].profit,
          dte: data[i].dte,
          delta: data[i].delta
        })
      }
      setAllTrades(_allTrades)

      if (strategy == "Wheel") {
        setYear(_allTrades[0].entryDate.substring(0, 4))
      } else {
        setYear(_allTrades[_allTrades.length - 1].entryDate.substring(0, 4))
      }
    }
  }, [data])

  // Filter the trades by year
  useEffect(() => {
    if (allTrades?.length > 0 && year && month) {
      let tradesWithDates = []
      for (let i = 0; i < allTrades.length; i++) {
        if (allTrades[i].leg == 1) {
          tradesWithDates.push({
            date: allTrades[i].entryDate,
            stock: allTrades[i].stock,
            trades: [allTrades[i]]
          })
        } else {
          tradesWithDates[tradesWithDates.length - 1].trades.push(allTrades[i])
        }
      }

      // Add the totals row
      for (let i = 0; i < tradesWithDates.length; i++) {
        let totalEntryPrice = 0
        let totalExitPrice = 0
        let totalProfit = 0
        let totalDelta = 0
        for (let j = 0; j < tradesWithDates[i].trades.length; j++) {
          let trade = tradesWithDates[i].trades[j]
          totalEntryPrice +=
            trade.type == "stock"
              ? trade.entryPrice
              : trade.ratio * trade.entryPrice
          totalExitPrice +=
            trade.type == "stock"
              ? trade.exitPrice
              : trade.ratio * trade.exitPrice
          totalProfit += trade.profit
          totalDelta +=
            trade.type == "stock" ? trade.delta : trade.ratio * trade.delta
        }
        tradesWithDates[i].totalEntryPrice = round(totalEntryPrice)
        tradesWithDates[i].totalExitPrice = round(totalExitPrice)
        tradesWithDates[i].totalProfit = round(totalProfit)
        tradesWithDates[i].totalDelta = round(totalDelta)
      }

      let _filteredTrades = tradesWithDates
      if (strategy !== "Wheel") {
        _filteredTrades = tradesWithDates.filter(trade => {
          return trade.date.substring(0, 4) == year
        })
      }
      _filteredTrades.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })
      if (_filteredTrades?.length > 0) {
        setFilteredTrades(_filteredTrades)
      } else {
        setFilteredTrades([])
      }
    }
  }, [allTrades, year])

  return (
    <div className="scrollbar w-full overflow-x-auto">
      <div className="min-w-[64rem]">
        <div>
          <div className="mt-4 max-w-[74rem] text-sm font-normal italic">
            The backtester runs every day 14 minutes before the close to ensure
            the highest quality of bid/ask quotes. Note that a $0.01 commission
            is used to open and close each trade. Also note that multiple trades
            may be active at the same time - this is to reduce path dependency
            and ensure the statistical validity of the returns of the entire
            strategy.
          </div>
          {strategy == "Wheel" ? (
            <div className="mt-5 flex flex-row items-center text-sm">
              <div className="mr-2.5">Jump to: </div>
              <div className="flex cursor-pointer flex-row space-x-4">
                {years.map(year => (
                  <div
                    key={year}
                    className="text-orange-600 underline"
                    onClick={() => {
                      if (filteredTrades?.length > 0) {
                        let monthElement = document.querySelector(
                          ".month-" + months[0].id
                        )
                        if (monthElement) {
                          monthElement.scrollIntoView({ behavior: "smooth" })
                        }

                        if (strategy == "Wheel") {
                          let yearElement = document.querySelector(
                            ".year-" + year
                          )
                          if (yearElement) {
                            yearElement.scrollIntoView({ behavior: "smooth" })
                          }
                        }
                      }
                    }}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-5 flex flex-row items-center text-sm">
              <div className="mr-2.5">Showing trades for:</div>
              <Listbox value={year} onChange={setYear} as="div">
                <ListboxButton className="flex w-24 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 font-medium ring-0 focus:outline-none focus:ring-0">
                  {year}
                  <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
                </ListboxButton>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <ListboxOptions className="scrollbar absolute mt-1.5 max-h-72 w-24 overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                    {years.map(year => (
                      <ListboxOption key={year} value={year} as={Fragment}>
                        {({ active, selected }) => (
                          <div
                            className={
                              "cursor-pointer select-none rounded-md px-2 py-2 hover:bg-slate-200/70 dark:hover:bg-zinc-600" +
                              (active
                                ? " bg-slate-200/70 dark:bg-zinc-600"
                                : "")
                            }
                          >
                            {year}
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Transition>
              </Listbox>
              <div className="ml-6 mr-2.5">Jump to: </div>
              <div className="flex cursor-pointer flex-row space-x-4">
                {months.map(month => (
                  <div
                    key={month.id}
                    className="text-orange-600 underline"
                    onClick={() => {
                      setMonth(month)
                      if (filteredTrades?.length > 0) {
                        let monthElement = document.querySelector(
                          ".month-" + month.id
                        )
                        if (monthElement) {
                          monthElement.scrollIntoView({ behavior: "smooth" })
                        }
                      }
                    }}
                  >
                    {month.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="scrollbar mt-5 min-h-0 flex-grow overflow-y-auto pb-6">
          {filteredTrades.map((day, i) => {
            return (
              <div
                key={day.date}
                className={
                  "month-" +
                  (parseInt(day.date.substring(5, 7)) - 1) +
                  " year-" +
                  day.date.substring(0, 4)
                }
              >
                <div className="flex flex-row items-center">
                  <div className="h-2.5 w-2.5 rounded-full bg-slate-300 dark:bg-zinc-500"></div>
                  <div className="ml-3 text-sm font-medium">
                    {formatFullDate(day.date)}
                  </div>
                  <div className="ml-3 flex flex-row rounded-full bg-slate-100/80 dark:bg-zinc-700 px-4 py-1.5 text-sm">
                    <div className="font-normal">Stock:</div>
                    <div className="ml-1.5 font-semibold">
                      ${day.stock.toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="ml-1 border-l border-slate-300 dark:border-zinc-500 pb-8 pt-2">
                  <div className="ml-4 rounded-lg border dark:border-zinc-600">
                    <div className="flex flex-row rounded-t-md bg-slate-100/80 dark:bg-zinc-700 py-2.5 pl-3 pr-3 text-sm">
                      <div className="w-64 2xl:w-72">Trade</div>
                      <div className="w-24 text-right">Entry Date</div>
                      <div className="w-36 text-right">Entry Stock Price</div>
                      <div className="w-24 text-right">Entry Price</div>
                      <div className="w-16 text-right">DTE</div>
                      <div className="w-20 text-right">Delta</div>
                      <div className="w-28 text-right">Exit Date</div>
                      <div className="w-32 text-right">Exit Stock Price</div>
                      <div className="w-24 text-right">Exit Price</div>
                      <div className="w-20 text-right">Profit</div>
                    </div>
                    {day.trades.map((trade, j) => {
                      return (
                        <div
                          key={(trade, j)}
                          className="flex flex-row items-center border-t dark:border-zinc-600 py-2.5 pl-3 pr-3 text-sm"
                        >
                          <div className="w-64 2xl:w-72">{trade.trade}</div>
                          <div className="w-24 text-right">
                            {formatDate(trade.entryDate)}
                          </div>
                          <div className="w-36 text-right">
                            ${trade.stock.toFixed(2)}
                          </div>
                          <div className="w-24 text-right">
                            ${trade.entryPrice.toFixed(2)}
                          </div>
                          <div className="w-16 text-right">{trade.dte}</div>
                          <div className="w-20 text-right">
                            {trade.delta.toFixed(2)}
                          </div>
                          <div className="w-28 text-right">
                            {formatDate(trade.exitDate)}
                          </div>
                          <div className="w-32 text-right">
                            ${trade.exitStock.toFixed(2)}
                          </div>
                          <div className="w-24 text-right">
                            ${trade.exitPrice.toFixed(2)}
                          </div>
                          <div className="w-20 text-right">${trade.profit}</div>
                        </div>
                      )
                    })}
                    <div className="flex flex-row border-t dark:border-zinc-600 py-2.5 pl-3 pr-3 text-sm">
                      <div className="w-64 2xl:w-72"></div>
                      <div className="w-24 text-right"></div>
                      <div className="w-36 text-right"></div>
                      <div className="w-24 text-right">
                        {"$" + day.totalEntryPrice.toFixed(2)}
                      </div>
                      <div className="w-16 text-right"></div>
                      <div className="w-20 text-right">
                        {day.totalDelta.toFixed(2)}
                      </div>
                      <div className="w-28 text-right"></div>
                      <div className="w-32 text-right"></div>
                      <div className="w-24 text-right">
                        {"$" + day.totalExitPrice.toFixed(2)}
                      </div>
                      <div
                        className={
                          "w-20 text-right " +
                          (day.totalProfit >= 0
                            ? "text-emerald-600"
                            : "text-red-600")
                        }
                      >
                        {"$" + day.totalProfit}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function getTradeHistoryQuery(day) {
  let legs = []
  for (let i = 0; i < day.trades.length; i++) {
    legs.push({
      type:
        day.trades[i].type.charAt(0).toUpperCase() +
        day.trades[i].type.slice(1),
      strike: day.trades[i].strike,
      expiration: day.trades[i].expirDate,
      ratio: day.trades[i].ratio
    })
  }
  let encodedLegs = encodeURIComponent(JSON.stringify(legs))

  return (
    "?ticker=" +
    day.trades[0].ticker +
    "&exitDate=" +
    day.trades[0].exitDate +
    "&legs=" +
    encodedLegs
  )
}

function convertTradeToStr(trade) {
  let ticker = trade.ticker
  let type = trade.optionType
  let longOrShort = Math.sign(trade.ratio) == 1 ? "Long" : "Short"
  let ratio = Math.abs(trade.ratio)
  let expirDate = formatDate(trade.expirDate)
  let strike = trade.strike
  return (
    longOrShort +
    " " +
    ratio +
    " " +
    ticker +
    " " +
    expirDate +
    " $" +
    strike +
    " " +
    type
  )
}

function getTypeColor(type) {
  if (type == "opened") {
    return "text-emerald-800 bg-emerald-600/20"
  } else if (type == "closed") {
    return "text-red-800 bg-red-600/20"
  } else {
    return "text-amber-800 bg-amber-600/20"
  }
}

function formatFullDate(date) {
  let year = date.substring(0, 4)
  let month = parseInt(date.substring(5, 7)) - 1
  let day = parseInt(date.substring(8, 10))
  return format(new Date(year, month, day), "MMMM do, yyyy")
}

const months = [
  { id: 0, name: "JAN" },
  { id: 1, name: "FEB" },
  { id: 2, name: "MAR" },
  { id: 3, name: "APR" },
  { id: 4, name: "MAY" },
  { id: 5, name: "JUN" },
  { id: 6, name: "JUL" },
  { id: 7, name: "AUG" },
  { id: 8, name: "SEP" },
  { id: 9, name: "OCT" },
  { id: 10, name: "NOV" },
  { id: 11, name: "DEC" }
]

function round(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}
