"use client"

import { useState, useEffect } from "react"
import { useRouter, usePathname, useSearchParams } from "next/navigation"
import Welcome from "/components/Welcome"
import FeaturedBacktests from "/components/FeaturedBacktests"
import AllBacktests from "/components/AllBacktests"
import MyFavorites from "/components/MyFavorites"
import CustomBacktests from "/components/CustomBacktests"
import useUserData from "/hooks/useUserData"
import useTradeIdeas from "/hooks/useTradeIdeas"
import { roundWithLetter } from "/lib/utils"
import useAvailableStrategies from "/hooks/useAvailableStrategies"
import { useAtom } from "jotai"
import { userIdAtom, themeAtom, ibPlatformAtom, fontSizeAtom } from "/lib/store"
import { decrypt3DES } from "/lib/encryption"
import { identify, track } from "/lib/mixpanel"

export default function Home() {
  const [userId, setUserId] = useAtom(userIdAtom)
  const [theme, setTheme] = useAtom(themeAtom)
  const [ibPlatform, setIbPlatform] = useAtom(ibPlatformAtom)
  const [fontSize, setFontSize] = useAtom(fontSizeAtom)

  const { data: userData } = useUserData({
    email: userId
  })
  const router = useRouter()
  const pathname = usePathname()
  const rawParams = useSearchParams()
  const rawParamsString = decodeURIComponent(rawParams.toString())
  let encodedParams = []
  if (rawParamsString) {
    const splitParams = rawParamsString.split("&")
    for (let i = 0; i < splitParams.length; i++) {
      let param = splitParams[i].split("=")
      encodedParams.push(param[0] + "=" + encodeURIComponent(param[1]))
    }
  }
  const params = new URLSearchParams(encodedParams.join("&"))

  useEffect(() => {
    async function asyncParseParams() {
      // Decode handshake and set variables
      if (params.has("handshake")) {
        let urlQueryParamString = await decrypt3DES(params.get("handshake"))
        let urlQueryParams = new URLSearchParams(urlQueryParamString)
        if (urlQueryParams.has("userid")) {
          setUserId(urlQueryParams.get("userid"))
          identify(urlQueryParams.get("userid"))
        }
        if (urlQueryParams.has("theme")) {
          setTheme(urlQueryParams.get("theme"))
        }
        if (urlQueryParams.has("ibPlatform")) {
          setIbPlatform(urlQueryParams.get("ibPlatform"))
        }
        if (urlQueryParams.has("fontSize")) {
          setFontSize(urlQueryParams.get("fontSize"))
        }
      }

      // If no handshake present then get variables from URL
      else {
        if (params.has("theme")) {
          setTheme(params.get("theme"))
        }
      }
    }
    asyncParseParams()
  }, [params])

  // Fetch available strategies and calculate total count
  const [totalCount, setTotalCount] = useState(0)
  const { data: availableStrategies } = useAvailableStrategies()
  useEffect(() => {
    if (availableStrategies) {
      let _totalCount = 0
      for (const [key, value] of Object.entries(availableStrategies)) {
        for (let i = 0; i < value.length; i++) {
          _totalCount += value[i].count
        }
      }
      setTotalCount(_totalCount)
    }
  }, [availableStrategies])

  // Fetch trade ideas
  const [tiTotalCount, setTiTotalCount] = useState(0)
  const { data: tradeIdeas } = useTradeIdeas({
    includedStrategies: [
      "IronButterfly",
      "IronCondor",
      "LongCallCalendar",
      "LongPutCalendar",
      "LongStraddle",
      "ShortPutSpread",
      "LongCall",
      "ShortCallSpread",
      "LongPut"
    ]
  })
  useEffect(() => {
    if (tradeIdeas) {
      setTiTotalCount(tradeIdeas.length)
    }
  }, [tradeIdeas])

  // Track page views in mixpanel
  useEffect(() => {
    if (userId && pathname.includes("userid") === false) {
      track("Page View", userId, {
        url: pathname,
        theme: theme,
        ibPlatform: ibPlatform,
        fontSize: fontSize
      })
    }
  }, [pathname, userId])

  return (
    <div className="scrollbar relative flex h-screen dark:text-white flex-1 flex-col overflow-x-hidden overflow-y-auto font-medium">
      <div className="w-full px-3 pt-3">
        <div className="sm:flex flex-row items-end">
          <div className="pr-6">
            <div className="text-2xl font-semibold">Options Backtester</div>
            <div className="mt-2 text-sm font-normal dark:text-zinc-300">
              Browse millions of the most robust, professionally tested
              backtests for the most common symbols and strategies. You may save
              your strategies to My Favorites, and backtest your own versions of
              strategies in the Custom Backtests tab.
            </div>
          </div>
          <div
            className="mt-2 sm:mt-0 text-center flex-none ml-auto border border-orange-600 text-orange-600 hover:bg-orange-600/10 cursor-pointer rounded-md px-4 py-2"
            onClick={e => {
              e.preventDefault()
              track("ORATS link clicked", userId, {
                theme: theme,
                ibPlatform: ibPlatform,
                fontSize: fontSize
              })
              window.top.postMessage(
                {
                  action: "url_open",
                  payload: {
                    url: "https://orats.com/ib?utm_source=ib&utm_campaign=iframe"
                  }
                },
                "*"
              )
            }}
          >
            Use backtests on ORATS
          </div>
        </div>
        <div className="scrollbar mt-4 flex select-none flex-row space-x-8 overflow-x-auto border-b border-slate-200 dark:border-zinc-600">
          <div
            className={
              "flex flex-none cursor-pointer flex-row pb-1 text-sm hover:text-black dark:hover:text-white" +
              (pathname.includes("/welcome") || pathname === "/"
                ? " border-b-2 border-black text-black dark:text-white dark:border-white"
                : " text-slate-500 dark:text-zinc-300")
            }
            onClick={() => {
              router.push("/welcome")
            }}
          >
            <div className="pt-px">Welcome</div>
          </div>
          <div
            className={
              "flex-none flex cursor-pointer flex-row pb-1 text-sm hover:text-black dark:hover:text-white" +
              (pathname.includes("/featured-backtests")
                ? " border-b-2 border-black text-black dark:text-white dark:border-white"
                : " text-slate-500 dark:text-zinc-300")
            }
            onClick={() => {
              router.push("/featured-backtests")
            }}
          >
            <div className="pt-px">Featured Backtests</div>
            <div className="ml-2 h-6 rounded-full bg-slate-200 px-2.5 py-1 text-xs text-slate-700 dark:bg-zinc-200 dark:text-zinc-700">
              {roundWithLetter(tiTotalCount, false)}
            </div>
          </div>
          <div
            className={
              "flex-none flex cursor-pointer flex-row pb-1 text-sm hover:text-black dark:hover:text-white" +
              (pathname.includes("/all-backtests")
                ? " border-b-2 border-black text-black dark:text-white dark:border-white"
                : " text-slate-500 dark:text-zinc-300")
            }
            onClick={() => {
              router.push("/all-backtests")
            }}
          >
            <div className="pt-px">All Backtests</div>
            <div className="ml-2 h-6 rounded-full bg-slate-200 px-2.5 py-1 text-xs text-slate-700 dark:bg-zinc-200 dark:text-zinc-700">
              {roundWithLetter(totalCount, false)}
            </div>
          </div>
          <div
            className={
              "flex flex-none cursor-pointer flex-row pb-1 text-sm hover:text-black dark:hover:text-white" +
              (pathname.includes("/my-favorites")
                ? " border-b-2 border-black text-black dark:text-white dark:border-white"
                : " text-slate-500 dark:text-zinc-300")
            }
            onClick={() => {
              router.push("/my-favorites")
            }}
          >
            <div className="pt-px">My Favorites</div>
            <div className="ml-2 h-6 rounded-full bg-slate-200 px-2.5 py-1 text-xs text-slate-700">
              {roundWithLetter(userData?.backtestFavorites?.length || 0, false)}
            </div>
          </div>
          <div
            className={
              "flex flex-none cursor-pointer flex-row pb-1 text-sm hover:text-black dark:hover:text-white" +
              (pathname.includes("/custom-backtests")
                ? " border-b-2 border-black text-black dark:text-white dark:border-white"
                : " text-slate-500 dark:text-zinc-300")
            }
            onClick={() => {
              router.push("/custom-backtests")
            }}
          >
            <div className="pt-px">Custom Backtests</div>
          </div>
        </div>
      </div>
      <>
        {pathname.includes("/welcome") || pathname === "/" ? (
          <Welcome />
        ) : pathname.includes("/featured-backtests") ? (
          <FeaturedBacktests />
        ) : pathname.includes("/all-backtests") ? (
          <AllBacktests />
        ) : pathname.includes("/my-favorites") ? (
          <MyFavorites />
        ) : pathname.includes("/custom-backtests") ? (
          <CustomBacktests />
        ) : null}
      </>
    </div>
  )
}
