import _axios from "axios"
import crypto from "crypto"

const axios = _axios.create()

const generateSignature = ({ url, method }) => {
  const timestamp = `${Date.now()}`
  const signature = crypto
    .createHmac("sha256", process.env.NEXT_PUBLIC_DBSS)
    .update(method.toUpperCase())
    .update(url)
    .update(timestamp)
    .digest("hex")
  return { signature, timestamp }
}

axios.interceptors.request.use(
  async config => {
    let { method, url } = config
    if (/^\/api\/orats\//.test(url)) {
      const { signature, timestamp } = generateSignature({ method, url })
      config.headers = {
        ...config.headers,
        ["cli-signature"]: signature,
        timestamp
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(undefined, async error => {
  return Promise.reject(error)
})

export default axios
