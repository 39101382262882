import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useTradeIdeas({ includedStrategies }) {
  return useQuery(
    ["tradeIdeas", includedStrategies],
    () => fetchTradeIdeas({ includedStrategies }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 60 * 1000 * 10,
      staleTime: 60 * 1000 * 10
    }
  )
}

const fetchTradeIdeas = async ({ includedStrategies }) => {
  const { data } = await axios.post(
    "/api/orats/backtests/performance/environment",
    includedStrategies
  )

  return data
}
