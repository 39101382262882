import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPipe } from "@fortawesome/pro-solid-svg-icons"

export const tickers = ["SPY"]

export const rankDirs = [
  {
    name: "Ascending",
    id: "asc"
  },
  {
    name: "Descending",
    id: "desc"
  }
]

export const strategies = [
  {
    name: "Long Call",
    id: "LongCall",
    category: "bullish",
    legs: 1
  },
  {
    name: "Short Put",
    id: "ShortPut",
    category: "bullish",
    legs: 1
  },
  {
    name: "Long Call Spread",
    id: "LongCallSpread",
    category: "bullish",
    legs: 2
  },
  {
    name: "Short Put Spread",
    id: "ShortPutSpread",
    category: "bullish",
    legs: 2
  },
  {
    name: "Put 1x2",
    id: "Put1x2",
    category: "bullish",
    legs: 2
  },
  {
    name: "Risk Reversal",
    id: "RiskReversal",
    category: "bullish",
    legs: 2
  },
  {
    name: "1x2 Call Backspread",
    id: "1x2CallBackspread",
    category: "bullish",
    legs: 2
  },
  {
    name: "Short Call",
    id: "ShortCall",
    category: "bearish",
    legs: 1
  },
  {
    name: "Long Put",
    id: "LongPut",
    category: "bearish",
    legs: 1
  },
  {
    name: "Short Call Spread",
    id: "ShortCallSpread",
    category: "bearish",
    legs: 2
  },
  {
    name: "Long Put Spread",
    id: "LongPutSpread",
    category: "bearish",
    legs: 2
  },
  {
    name: "Call 1x2",
    id: "Call1x2",
    category: "bearish",
    legs: 2
  },
  {
    name: "Short Risk Reversal",
    id: "ShortRiskReversal",
    category: "bearish",
    legs: 2
  },
  {
    name: "1x2 Put Backspread",
    id: "1x2PutBackspread",
    category: "bearish",
    legs: 2
  },
  {
    name: "Long Call Calendar",
    id: "LongCallCalendar",
    category: "neutral",
    legs: 2
  },
  {
    name: "Long Put Calendar",
    id: "LongPutCalendar",
    category: "neutral",
    legs: 2
  },
  {
    name: "Short Call Calendar",
    id: "ShortCallCalendar",
    category: "neutral",
    legs: 2
  },
  {
    name: "Short Put Calendar",
    id: "ShortPutCalendar",
    category: "neutral",
    legs: 2
  },
  {
    name: "Long Straddle",
    id: "LongStraddle",
    category: "neutral",
    legs: 2
  },
  {
    name: "Short Straddle",
    id: "ShortStraddle",
    category: "neutral",
    legs: 2
  },
  {
    name: "Long Strangle",
    id: "LongStrangle",
    category: "neutral",
    legs: 2
  },
  {
    name: "Short Strangle",
    id: "ShortStrangle",
    category: "neutral",
    legs: 2
  },
  {
    name: "Iron Condor",
    id: "IronCondor",
    category: "neutral",
    legs: 4
  },
  {
    name: "Long Call Butterfly",
    id: "LongCallButterfly",
    category: "neutral",
    legs: 3
  },
  {
    name: "Long Put Butterfly",
    id: "LongPutButterfly",
    category: "neutral",
    legs: 3
  },
  {
    name: "Short Call Butterfly",
    id: "ShortCallButterfly",
    category: "neutral",
    legs: 3
  },
  {
    name: "Short Put Butterfly",
    id: "ShortPutButterfly",
    category: "neutral",
    legs: 3
  },
  {
    name: "Iron Butterfly",
    id: "IronButterfly",
    category: "neutral",
    legs: 4
  },
  {
    name: "Wheel",
    id: "Wheel",
    category: "bullish",
    legs: 2
  }
]

export const entryTriggers = [
  {
    name: "VIX",
    id: "entryVix",
    levels: [
      { name: "Low (<15)", id: "low", shorthand: "Low" },
      { name: "Moderate (15-20)", id: "moderate", shorthand: "Moderate" },
      { name: "High (>20)", id: "high", shorthand: "High" }
    ]
  },
  {
    name: "SMA",
    id: "entrySma",
    levels: [
      { name: "Above 50d", id: "above50d" },
      { name: "Above 200d", id: "above200d" },
      { name: "Below 50d", id: "below50d" },
      { name: "Below 200d", id: "below200d" }
    ]
  },
  {
    name: "14d RSI",
    id: "entryRsi",
    levels: [
      { name: "Oversold (<40)", id: "oversold", shorthand: "Oversold" },
      { name: "Moderate (40-60)", id: "moderate", shorthand: "Moderate" },
      { name: "Overbought (>60)", id: "overbought", shorthand: "Overbought" }
    ]
  },
  {
    name: "IV Percentile 1y",
    id: "entryIvPct",
    levels: [
      { name: "Low (<33)", id: "low", shorthand: "Low" },
      { name: "Moderate (33-66)", id: "moderate", shorthand: "Moderate" },
      { name: "High (>66)", id: "high", shorthand: "High" }
    ]
  },
  {
    name: "Slope Percentile 1y",
    id: "entrySlopePct",
    levels: [
      { name: "Low (<33)", id: "low", shorthand: "Low" },
      { name: "Moderate (33-66)", id: "moderate", shorthand: "Moderate" },
      { name: "High (>66)", id: "high", shorthand: "High" }
    ]
  }
]

export const exitTriggers = [
  {
    name: "Stop Loss",
    id: "exitStopLoss",
    levels: [
      { name: "-25%", id: "-25" },
      { name: "-50%", id: "-50" },
      { name: "-75%", id: "-75" }
    ]
  },
  {
    name: "Profit Target",
    id: "exitProfit",
    levels: [
      { name: "+25%", id: "25" },
      { name: "+50%", id: "50" },
      { name: "+75%", id: "75" },
      { name: "+100%", id: "100" },
      { name: "+150%", id: "150" },
      { name: "+300%", id: "300" }
    ]
  }
]

export const ranks = [
  {
    name: "Best overall performance",
    description:
      "This ranking looks at the following columns and applies the following weights: Annual Return % (Overall) (33.3%), P&L % / Day Avg. (16.6%), Sharpe Ratio (33.3%), and Sortino Ratio (16.6%).",
    id: "bestOverallPerformance"
  },
  {
    name: "Best conservative winner",
    description:
      "This ranking looks at the following columns and applies the following weights: Annual Return % (Overall) (18.2%), Max Drawdown % (9%), Strategy Win Rate % (9%), P&L % / Day Avg. (18.2%), Worst Annual Return % (9%), Worst Monthly Return % (9%), Sharpe Ratio (9%), and Annual Sortino (18.2%).",
    id: "bestConservativeWinner"
  },
  {
    name: "Best return on risk",
    description:
      "This ranking calculates the following metric: (P&L % / Day Avg.) /  -1 * Average (Worst Monthly Return %, Worst Annual Return %, Max Drawdown %)",
    id: "bestReturnOnRisk"
  },
  { name: "Annual Return % (Overall)", id: "annualReturn" },
  { name: "P&L % / Day Avg.", id: "plPerDayAvgPct" },
  { name: "Annual Return % (Last year)", id: "annualReturn1Yr" },
  { name: "Annual Return % (During bear markets)", id: "annualReturnBear" },
  { name: "Annual Return % (During bull markets)", id: "annualReturnBull" },
  { name: "Annual Margin Return % (Overall)", id: "annualMarginReturn" },
  { name: "Sharpe Ratio", id: "sharpe" },
  { name: "Sortino Ratio", id: "sortino" },
  { name: "Max Drawdown %", id: "maxDrawDown" },
  { name: "Worst Monthly Return %", id: "worstMonthRtn" },
  { name: "Worst Annual Return %", id: "worstYearRtn" },
  { name: "Strategy Win Rate %", id: "stratWinRate" }
]

export const performanceColumns = [
  {
    name: "Annual Return %",
    subtitle: "Overall",
    description:
      "Overall annual return from Jan. 2007 - Present. Returns are calculated using notional value.",
    id: "annualReturn",
    shorthand: "Annual Return % (Overall)",
    category: "return"
  },
  {
    name: "P&L % / Day Avg.",
    id: "plPerDayAvgPct",
    category: "pl"
  },
  {
    name: "% of Time in Market",
    id: "pctOfTimeInMarket",
    category: "other"
  },
  {
    name: "Annual Return %",
    subtitle: "Last year",
    description:
      "Annual return over the last year. Returns are calculated using notional value.",
    id: "annualReturn1Yr",
    shorthand: "Annual Return % (1 Yr)",
    category: "return"
  },
  {
    name: "Annual Return %",
    subtitle: "Last 5 years",
    description:
      "Annual return over the last 5 years. Returns are calculated using notional value.",
    id: "annualReturn5Yr",
    shorthand: "Annual Return % (5 Yr)",
    category: "return"
  },
  {
    name: "Annual Return %",
    subtitle: "During bear markets",
    description:
      "Only these bearish market timeframes are included: Financial crisis (Nov. 2007 - Feb. 2009), Volmaggedon (Feb. 2018), Late 2018 bear market (Oct. 2018 - Dec. 2018), Covid crash (Feb. 2020 - Mar. 2020), 2022 bear market (Jan. 2022 - Dec. 2022). Returns are calculated using notional value.",
    id: "annualReturnBear",
    shorthand: "Annual Return % (Bear)",
    category: "return"
  },
  {
    name: "Annual Return %",
    subtitle: "During bull markets",
    description:
      "Only these bullish market timeframes are included: 2017 Bull market (Nov. 2016 - Dec. 2017), Covid rebound (Apr. 2020 - Aug. 2020), 2021 Bull market (Jan. 2021 - Aug. 2021). Returns are calculated using notional value.",
    id: "annualReturnBull",
    shorthand: "Annual Return % (Bull)",
    category: "return"
  },
  {
    name: "Annual Margin Return %",
    subtitle: "Overall",
    description:
      "Overall annual return from Jan. 2007 - Present. Returns are calculated using margin.",
    id: "annualMarginReturn",
    shorthand: "Annual Margin Return %",
    category: "return"
  },
  {
    name: "Sharpe Ratio",
    id: "sharpe",
    category: "risk"
  },
  {
    name: "Sortino Ratio",
    id: "sortino",
    category: "risk"
  },
  {
    name: "Annual Volatility %",
    id: "volatility",
    category: "risk"
  },
  {
    name: "Max Drawdown %",
    id: "maxDrawDown",
    category: "risk"
  },
  {
    name: "Drawdown Days",
    id: "drawDownDays",
    category: "risk"
  },
  {
    name: "Best Monthly Return %",
    id: "bestMonthRtn",
    category: "return"
  },
  {
    name: "Worst Monthly Return %",
    id: "worstMonthRtn",
    category: "return"
  },
  {
    name: "Best Annual Return %",
    id: "bestYearRtn",
    category: "return"
  },
  {
    name: "Worst Annual Return %",
    id: "worstYearRtn",
    category: "return"
  },
  {
    name: "Best Trade P&L ($)",
    id: "bestTradePL",
    category: "pl"
  },
  {
    name: "Best Trade P&L (%)",
    id: "bestTradePct",
    category: "pl"
  },
  {
    name: "Worst Trade P&L ($)",
    id: "worstTradePL",
    category: "pl"
  },
  {
    name: "Worst Trade P&L (%)",
    id: "worstTradePct",
    category: "pl"
  },
  {
    name: "P&L / Trade Avg.",
    id: "plPerTradeAvg",
    category: "pl"
  },
  {
    name: "P&L % / Trade Avg.",
    id: "plPerTradeAvgPct",
    category: "pl"
  },
  {
    name: "P&L / Day Avg.",
    id: "plPerDayAvg",
    category: "pl"
  },
  {
    name: "Strategy Win Rate %",
    id: "stratWinRate",
    category: "other"
  },
  {
    name: "Days In Trade Avg.",
    id: "daysInTradeAvg",
    category: "other"
  },
  {
    name: "Total Strategy Trades",
    id: "totStratTrades",
    category: "other"
  },
  {
    name: "Total Strategy P&L ($)",
    id: "totStratPL",
    category: "pl"
  },
  {
    name: "Total Strategy P&L (%)",
    id: "totStratPLPct",
    category: "pl"
  },
  {
    name: "Cr. or Dr. Per Trade Avg.",
    id: "creditReceivedPerTradeAvg",
    category: "other"
  },
  {
    name: "Margin Per Trade Avg.",
    id: "marginPerTradeAvg",
    category: "other"
  },
  {
    name: "Most Margin Used",
    id: "mostMarginUsed",
    category: "other"
  },
  {
    name: "Least Margin Used",
    id: "leastMarginUsed",
    category: "other"
  },
  {
    name: "Best Trade % Margin",
    id: "bestTradePctMargin",
    category: "other"
  },
  {
    name: "Worst Trade % Margin",
    id: "worstTradePctMargin",
    category: "other"
  },
  {
    name: "Margin to Stock %",
    id: "marginToStockPct",
    category: "other"
  },
  {
    name: "Reward:Risk Avg.",
    id: "riskRewardAvg",
    category: "risk"
  }
]

export function formatPerformanceMetric(id, num) {
  let _num = num
  if (id == "riskRewardAvg") {
    if (num < 1) {
      _num = Math.round((1 / num) * 10) / 10
      return "1 : " + _num + " (" + round2(num) + ")"
    } else {
      _num = Math.round(num * 10) / 10
      return _num + " : 1" + " (" + round2(num) + ")"
    }
  } else {
    return num
  }
}

export function getLevel({ backtest, indicator }) {
  let entryAndExitTriggers = entryTriggers.concat(exitTriggers)
  let levels = entryAndExitTriggers.find(obj => obj.id == indicator).levels
  let level = levels.find(obj => obj.id == backtest[indicator])
  if (level) {
    if (level.hasOwnProperty("shorthand")) {
      return level.shorthand
    } else {
      return level.name
    }
  } else {
    return "None"
  }
}

export const defaultFilters = {
  dte1Target: {
    min: null,
    max: null
  },
  dte2Target: {
    min: null,
    max: null
  },
  dte3Target: {
    min: null,
    max: null
  },
  dte4Target: {
    min: null,
    max: null
  },
  delta1Target: {
    min: null,
    max: null
  },
  delta2Target: {
    min: null,
    max: null
  },
  delta3Target: {
    min: null,
    max: null
  },
  delta4Target: {
    min: null,
    max: null
  },
  lrDteLeg1Leg2Min: {
    min: null,
    max: null
  },
  lrDteLeg1Leg2Max: {
    min: null,
    max: null
  },
  lrDteLeg2Leg3Min: {
    min: null,
    max: null
  },
  lrDteLeg2Leg3Max: {
    min: null,
    max: null
  },
  lrDteLeg3Leg4Min: {
    min: null,
    max: null
  },
  lrDteLeg3Leg4Max: {
    min: null,
    max: null
  },
  lrSwLeg1Leg2Min: {
    min: null,
    max: null
  },
  lrSwLeg1Leg2Max: {
    min: null,
    max: null
  },
  lrSwLeg2Leg3Min: {
    min: null,
    max: null
  },
  lrSwLeg2Leg3Max: {
    min: null,
    max: null
  },
  lrSwLeg3Leg4Min: {
    min: null,
    max: null
  },
  lrSwLeg3Leg4Max: {
    min: null,
    max: null
  },
  spreadYieldLevel: null,
  entryVix: null,
  entrySma: null,
  entryRsi: null,
  entryIvPct: null,
  entrySlopePct: null,
  exitStopLoss: null,
  exitProfit: null,
  annualReturn: {
    min: null,
    max: null
  },
  annualReturn1Yr: {
    min: null,
    max: null
  },
  annualReturn5Yr: {
    min: null,
    max: null
  },
  annualReturnBear: {
    min: null,
    max: null
  },
  annualReturnBull: {
    min: null,
    max: null
  },
  annualMarginReturn: {
    min: null,
    max: null
  },
  sharpe: {
    min: null,
    max: null
  },
  sortino: {
    min: null,
    max: null
  },
  volatility: {
    min: null,
    max: null
  },
  maxDrawDown: {
    min: null,
    max: null
  },
  drawDownDays: {
    min: null,
    max: null
  },
  bestMonthRtn: {
    min: null,
    max: null
  },
  worstMonthRtn: {
    min: null,
    max: null
  },
  bestYearRtn: {
    min: null,
    max: null
  },
  worstYearRtn: {
    min: null,
    max: null
  },
  bestTradePL: {
    min: null,
    max: null
  },
  bestTradePct: {
    min: null,
    max: null
  },
  worstTradePL: {
    min: null,
    max: null
  },
  worstTradePct: {
    min: null,
    max: null
  },
  plPerTradeAvg: {
    min: null,
    max: null
  },
  plPerTradeAvgPct: {
    min: null,
    max: null
  },
  plPerDayAvg: {
    min: null,
    max: null
  },
  plPerDayAvgPct: {
    min: null,
    max: null
  },
  stratWinRate: {
    min: null,
    max: null
  },
  daysInTradeAvg: {
    min: null,
    max: null
  },
  totStratTrades: {
    min: null,
    max: null
  },
  totStratPL: {
    min: null,
    max: null
  },
  totStratPLPct: {
    min: null,
    max: null
  },
  pctOfTimeInMarket: {
    min: null,
    max: null
  },
  creditReceivedPerTradeAvg: {
    min: null,
    max: null
  },
  marginPerTradeAvg: {
    min: null,
    max: null
  },
  mostMarginUsed: {
    min: null,
    max: null
  },
  leastMarginUsed: {
    min: null,
    max: null
  },
  bestTradePctMargin: {
    min: null,
    max: null
  },
  worstTradePctMargin: {
    min: null,
    max: null
  },
  marginToStockPct: {
    min: null,
    max: null
  },
  riskRewardAvg: {
    min: null,
    max: null
  }
}

export const risks = [
  {
    name: "High risk, high reward",
    id: "high"
  },
  {
    name: "Balanced risk/reward",
    id: "balanced"
  },
  {
    name: "Low risk, low reward",
    id: "low"
  }
]

export const timeframes = [
  {
    name: "Short-term (0-14 days)",
    id: "short"
  },
  {
    name: "Medium-term (15 days - 3 months)",
    id: "medium"
  },
  {
    name: "Long-term (>3 months)",
    id: "long"
  }
]

export const outlooks = [
  {
    name: "Bullish",
    id: "bullish"
  },
  {
    name: "Neutral",
    id: "neutral"
  },
  {
    name: "Bearish",
    id: "bearish"
  }
]

export function DTE({ backtest }) {
  let isSameDte = true

  if (
    backtest.dte2Target !== null &&
    backtest.dte1Target !== backtest.dte2Target
  ) {
    isSameDte = false
  }
  if (
    backtest.dte3Target !== null &&
    backtest.dte1Target !== backtest.dte3Target
  ) {
    isSameDte = false
  }
  if (
    backtest.dte4Target !== null &&
    backtest.dte1Target !== backtest.dte4Target
  ) {
    isSameDte = false
  }

  if (isSameDte) {
    return <>{backtest.dte1Target && <div>{backtest.dte1Target}</div>}</>
  } else {
    return (
      <>
        {backtest.dte1Target && <div>{backtest.dte1Target}</div>}
        {backtest.dte2Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.dte2Target}
          </div>
        )}
        {backtest.dte3Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.dte3Target}
          </div>
        )}
        {backtest.dte4Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.dte4Target}
          </div>
        )}
      </>
    )
  }
}

export function Strikes({ backtest }) {
  let isSameStrikes = true

  if (
    backtest.delta2Target !== null &&
    backtest.delta1Target !== backtest.delta2Target
  ) {
    isSameStrikes = false
  }
  if (
    backtest.delta3Target !== null &&
    backtest.delta1Target !== backtest.delta3Target
  ) {
    isSameStrikes = false
  }
  if (
    backtest.delta4Target !== null &&
    backtest.delta1Target !== backtest.delta4Target
  ) {
    isSameStrikes = false
  }

  if (isSameStrikes) {
    return (
      <>
        {backtest.delta1Target && <div>{backtest.delta1Target.toFixed(2)}</div>}
      </>
    )
  } else {
    return (
      <>
        {backtest.delta1Target && <div>{backtest.delta1Target.toFixed(2)}</div>}
        {backtest.delta2Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.delta2Target.toFixed(2)}
          </div>
        )}
        {backtest.delta3Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.delta3Target.toFixed(2)}
          </div>
        )}
        {backtest.delta4Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300"
            />{" "}
            {backtest.delta4Target.toFixed(2)}
          </div>
        )}
      </>
    )
  }
}

export function round3(num) {
  return Math.round(num * 1000) / 1000
}

export function round2(num) {
  return Math.round(num * 100) / 100
}

export const symbols = [
  "AAPL",
  "ADBE",
  "AMD",
  "AMZN",
  "BABA",
  "BAC",
  "BITO",
  "C",
  "COST",
  "DIA",
  "DIS",
  "DJX",
  "EEM",
  "EFA",
  "EWZ",
  "FXI",
  "GDX",
  "GLD",
  "GOOG",
  "HD",
  "HYG",
  "IBB",
  "IGN",
  "INTC",
  "ITB",
  "IWM",
  "IYR",
  "JNJ",
  "KBE",
  "KRE",
  "LULU",
  "MDY",
  "META",
  "MSFT",
  "NDX",
  "NFLX",
  "NVDA",
  "OIH",
  "PLTR",
  "PYPL",
  "SHOP",
  "SLV",
  "SMH",
  "SPY",
  "SPX",
  "TLT",
  "TQQQ",
  "TSLA",
  "VIX",
  "XBI",
  "XHB",
  "XLB",
  "XLC",
  "XLE",
  "XLF",
  "XLI",
  "XLK",
  "XLP",
  "XLRE",
  "XLU",
  "XLV",
  "XLY",
  "XOM",
  "XOP",
  "XSP"
]

export const defaultBacktests = [
  {
    name: "Long Call",
    id: "LongCall",
    category: "bullish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "call"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Short Put",
    id: "ShortPut",
    category: "bullish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "put"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Short Put Spread",
    id: "ShortPutSpread",
    category: "bullish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "put"
        },
        {
          deltaMax: 0.3,
          deltaMin: 0.1,
          deltaTarget: 0.15,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "put"
        }
      ],
      lrDte: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      lrSw: [
        {
          max: null,
          min: 2,
          name: "leg1Leg2"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Long Put",
    id: "LongPut",
    category: "bearish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "put"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Short Call",
    id: "ShortCall",
    category: "bearish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "call"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Short Call Spread",
    id: "ShortCallSpread",
    category: "bearish",
    input: {
      legs: [
        {
          deltaMax: 0.5,
          deltaMin: 0.2,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "call"
        },
        {
          deltaMax: 0.3,
          deltaMin: 0.1,
          deltaTarget: 0.15,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "call"
        }
      ],
      lrDte: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      lrSw: [
        {
          max: -2,
          min: null,
          name: "leg1Leg2"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Iron Condor",
    id: "IronCondor",
    category: "neutral",
    input: {
      legs: [
        {
          deltaMax: 0.2,
          deltaMin: 0.05,
          deltaTarget: 0.1,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "call"
        },
        {
          deltaMax: 0.4,
          deltaMin: 0.15,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "call"
        },
        {
          deltaMax: 0.4,
          deltaMin: 0.15,
          deltaTarget: 0.3,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "put"
        },
        {
          deltaMax: 0.2,
          deltaMin: 0.05,
          deltaTarget: 0.1,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "put"
        }
      ],
      lrDte: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        },
        {
          max: 0,
          min: 0,
          name: "leg2Leg3"
        },
        {
          max: 0,
          min: 0,
          name: "leg3Leg4"
        }
      ],
      lrSw: [
        {
          max: null,
          min: 2,
          name: "leg1Leg2"
        },
        {
          max: null,
          min: 2,
          name: "leg2Leg3"
        },
        {
          max: null,
          min: 2,
          name: "leg3Leg4"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Iron Butterfly",
    id: "IronButterfly",
    category: "neutral",
    input: {
      legs: [
        {
          deltaMax: 0.2,
          deltaMin: 0.05,
          deltaTarget: 0.1,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "call"
        },
        {
          deltaMax: 0.6,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "call"
        },
        {
          deltaMax: 0.6,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "put"
        },
        {
          deltaMax: 0.2,
          deltaMin: 0.05,
          deltaTarget: 0.1,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "put"
        }
      ],
      lrDte: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        },
        {
          max: 0,
          min: 0,
          name: "leg2Leg3"
        },
        {
          max: 0,
          min: 0,
          name: "leg3Leg4"
        }
      ],
      lrSw: [
        {
          max: null,
          min: 2,
          name: "leg1Leg2"
        },
        {
          max: null,
          min: 0,
          name: "leg2Leg3"
        },
        {
          max: null,
          min: 2,
          name: "leg3Leg4"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Long Call Calendar",
    id: "LongCallCalendar",
    category: "neutral",
    input: {
      legs: [
        {
          deltaMax: 0.55,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 120,
          dteMin: 40,
          dteTarget: 90,
          ratio: 1,
          type: "call"
        },
        {
          deltaMax: 0.55,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "call"
        }
      ],
      lrDte: [
        {
          max: null,
          min: 2,
          name: "leg1Leg2"
        }
      ],
      lrSw: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Long Put Calendar",
    id: "LongPutCalendar",
    category: "neutral",
    input: {
      legs: [
        {
          deltaMax: 0.55,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 120,
          dteMin: 40,
          dteTarget: 90,
          ratio: 1,
          type: "put"
        },
        {
          deltaMax: 0.55,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: -1,
          type: "put"
        }
      ],
      lrDte: [
        {
          max: null,
          min: 2,
          name: "leg1Leg2"
        }
      ],
      lrSw: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  },
  {
    name: "Long Straddle",
    id: "LongStraddle",
    category: "neutral",
    input: {
      legs: [
        {
          deltaMax: 0.6,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "call"
        },
        {
          deltaMax: 0.6,
          deltaMin: 0.4,
          deltaTarget: 0.5,
          dteMax: 45,
          dteMin: 20,
          dteTarget: 30,
          ratio: 1,
          type: "put"
        }
      ],
      lrDte: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      lrSw: [
        {
          max: 0,
          min: 0,
          name: "leg1Leg2"
        }
      ],
      spreadYield: {
        max: null,
        min: null,
        target: null
      },
      strikeDiff: {
        max: null,
        min: null,
        target: null
      }
    }
  }
]
