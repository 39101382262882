import { useState, useEffect, Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleNotch,
  faPipe,
  faTimes,
  faChevronDown,
  faSquareCheck
} from "@fortawesome/pro-solid-svg-icons"
import { faSquare } from "@fortawesome/pro-regular-svg-icons"
import {
  Transition,
  Popover,
  PopoverButton,
  PopoverPanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Switch
} from "@headlessui/react"
import axios from "@/lib/axios"
import { strategyNames } from "/lib/utils"
import useBacktestPerformance from "/hooks/useBacktestPerformance"
import useBacktestReturns from "/hooks/useBacktestReturns"
import useBacktestInputs from "/hooks/useBacktestInputs"
import useStockReturns from "/hooks/useStockReturns"
import Graph from "./Graph"
import PerformanceMetrics from "./PerformanceMetrics"
import MonthlyReturns from "./MonthlyReturns"
import TradeLog from "./TradeLog"
import EditBacktest from "./EditBacktest"
import ShowLevel from "./ShowLevel"
import { getLevel, strategies, round2 } from "./Shared.js"
import useUserData from "/hooks/useUserData"
import { useQueryClient } from "react-query"
import { useAtom } from "jotai"
import {
  btFiltersAtom,
  btStrategyAtom,
  btTickerAtom,
  btCurrentEnvironmentToggleAtom,
  userIdAtom,
  themeAtom,
  ibPlatformAtom,
  fontSizeAtom
} from "/lib/store"
import { useRouter } from "next/navigation"
import cloneDeep from "lodash.clonedeep"
import { usePathname } from "next/navigation"
import { track } from "/lib/mixpanel"

export default function BacktestDetails({
  selectedBacktestId,
  setSelectedBacktestId,
  custom
}) {
  const queryClient = useQueryClient()

  const [userId, setUserId] = useAtom(userIdAtom)
  const [theme, setTheme] = useAtom(themeAtom)
  const [ibPlatform, setIbPlatform] = useAtom(ibPlatformAtom)
  const [fontSize, setFontSize] = useAtom(fontSizeAtom)

  const { data: backtestPerformance } = useBacktestPerformance({
    id: selectedBacktestId,
    custom,
    userId: userId
  })
  const { data: backtestReturns } = useBacktestReturns({
    id: selectedBacktestId,
    custom
  })
  const { data: stockReturns } = useStockReturns({
    ticker: backtestPerformance?.ticker
  })
  const { data: backtestInputs } = useBacktestInputs({
    baseId: backtestPerformance?.baseId || backtestPerformance?.id,
    custom
  })
  const { data: userData } = useUserData({
    email: userId
  })
  const [selectedTab, setSelectedTab] = useState("Graph")
  const [graphData, setGraphData] = useState([])
  const [savedToFavorites, setSavedToFavorites] = useState(false)
  const [capAlloc, setCapAlloc] = useState(0.2)
  const [startingCapital, setStartingCapital] = useState(100000)
  const [compounding, setCompounding] = useState(false)

  useEffect(() => {
    if (custom) {
      track("Custom backtest viewed", userId, {
        id: selectedBacktestId,
        theme: theme,
        ibPlatform: ibPlatform,
        fontSize: fontSize
      })
    } else {
      track("Pre-canned backtest viewed", userId, {
        id: selectedBacktestId,
        theme: theme,
        ibPlatform: ibPlatform,
        fontSize: fontSize
      })
    }
  }, [])

  useEffect(() => {
    for (let i = 0; i < userData?.backtestFavorites?.length; i++) {
      if (userData.backtestFavorites[i].id == selectedBacktestId) {
        setSavedToFavorites(true)
        break
      }
    }
  }, [userData])

  useEffect(() => {
    if (backtestReturns && stockReturns) {
      // Get the start and end dates
      let startDate = convertToDate(backtestReturns.returns[0].date)
      let endDate = convertToDate(
        backtestReturns.returns[backtestReturns.returns.length - 1].date
      )

      // Calculate the notional stock returns
      let _stockReturns = []
      for (let i = 0; i < stockReturns.data.length; i++) {
        let tradeDate = convertToDate(stockReturns.data[i].tradeDate)
        if (
          tradeDate.getTime() >= startDate.getTime() &&
          tradeDate.getTime() <= endDate.getTime()
        ) {
          if (_stockReturns.length == 0) {
            _stockReturns.push({
              date: stockReturns.data[i].tradeDate,
              return: 0
            })
          } else {
            _stockReturns.push({
              date: stockReturns.data[i].tradeDate,
              return:
                stockReturns.data[i].clsPx / stockReturns.data[i - 1].clsPx - 1
            })
          }
        }
      }

      let _graphData = []
      let stockReturn = startingCapital
      let strategyReturn = startingCapital

      for (let i = 0; i < _stockReturns.length; i++) {
        let obj = {}

        // Add the date
        let rawDate = _stockReturns[i].date
        obj.date = convertToDate(rawDate)

        // Calculate the stock return dollar amount
        if (compounding) {
          stockReturn += stockReturn * 1 * _stockReturns[i].return
        } else {
          stockReturn += startingCapital * 1 * _stockReturns[i].return
        }
        obj.stockReturn = stockReturn

        // Calculate strategy return dollar amount
        let rawStrategyReturnIndex = backtestReturns.returns.findIndex(obj => {
          return obj.date == rawDate
        })
        if (rawStrategyReturnIndex !== -1) {
          if (compounding) {
            strategyReturn +=
              strategyReturn *
              capAlloc *
              backtestReturns.returns[rawStrategyReturnIndex].marginReturn
          } else {
            strategyReturn +=
              startingCapital *
              capAlloc *
              backtestReturns.returns[rawStrategyReturnIndex].marginReturn
          }
        }
        obj.strategyReturn = strategyReturn

        // Add the object to the array
        _graphData.push(obj)
      }
      setGraphData(_graphData)
    }
  }, [backtestReturns, stockReturns, capAlloc, startingCapital, compounding])

  return backtestPerformance && backtestReturns && stockReturns ? (
    <>
      <div className="relative z-10 flex flex-row items-start">
        <div className="xl:flex xl:flex-row xl:flex-grow">
          <div className="flex flex-row items-end mt-3">
            <div className="text-4xl font-bold">
              {backtestPerformance?.ticker}
            </div>
            {strategyNames.find(obj => {
              return obj.id === backtestPerformance?.strategy
            })?.name ? (
              <img
                className="mb-0.5 ml-8 w-10"
                src={"/strategies/" + backtestPerformance?.strategy + ".svg"}
              />
            ) : null}
            <div className="ml-4 truncate text-xl font-semibold">
              {backtestPerformance?.strategy.replace(/([A-Z])/g, " $1").trim()}
            </div>
          </div>
          <div className="flex flex-row items-end flex-wrap xl:ml-auto">
            <div
              className="mr-4 mt-3 text-center flex-none border border-orange-600 text-orange-600 hover:bg-orange-600/10 cursor-pointer rounded-md px-4 py-2"
              onClick={e => {
                e.preventDefault()
                track("ORATS link clicked", userId, {
                  theme: theme,
                  ibPlatform: ibPlatform,
                  fontSize: fontSize
                })
                window.top.postMessage(
                  {
                    action: "url_open",
                    payload: {
                      url: "https://orats.com/ib?utm_source=ib&utm_campaign=iframe"
                    }
                  },
                  "*"
                )
              }}
            >
              Use backtest on ORATS
            </div>
            <FindSimilar
              backtest={backtestPerformance}
              setSelectedBacktestId={setSelectedBacktestId}
            />
            <EditBacktest
              backtest={backtestInputs}
              setSelectedBacktestId={setSelectedBacktestId}
            />
            {!custom &&
              (savedToFavorites ? (
                <div className="mr-4 mt-3 flex-none rounded-md border border-blue-600 dark:border-blue-500 bg-blue-600 dark:bg-blue-500 px-3 py-2 text-white">
                  Saved to my favorites
                </div>
              ) : (
                <div
                  className="mr-4 mt-3 flex-none cursor-pointer rounded-md border border-blue-600 dark:border-blue-500 px-3 py-2 text-blue-600 dark:text-blue-500 hover:bg-blue-600/10 dark:hover:bg-blue-500/10"
                  onClick={async () => {
                    setSavedToFavorites(true)
                    await axios.post("/api/orats/backtests/favorites", {
                      email: userId,
                      backtestId: backtestPerformance?.id
                        ? backtestPerformance.id
                        : backtestPerformance?.baseId
                    })
                    queryClient.invalidateQueries("userData")
                  }}
                >
                  Save to my favorites
                </div>
              ))}
          </div>
        </div>
        {setSelectedBacktestId !== null && (
          <>
            <div
              className="flex aspect-square ml-auto mt-3 h-11 w-11 cursor-pointer items-center justify-center rounded-full border border-red-600 text-red-600 hover:bg-red-600/10"
              onClick={() => {
                setSelectedBacktestId(null)
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </>
        )}
      </div>
      <div className="mt-2 flex flex-row flex-wrap">
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Annual Return % (Overall)
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            {backtestPerformance?.annualReturn}
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Sharpe Ratio
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            {backtestPerformance?.sharpe}
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Max Drawdown %
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            {backtestPerformance?.maxDrawDown}
          </div>
        </div>
        <div className="mr-3 mt-3 w-0.5 rounded-full bg-slate-200 dark:bg-zinc-700"></div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">DTE</div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            <DTE backtest={backtestPerformance} />
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            DTE Diff
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            <DTEDiff backtest={backtestPerformance} />
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Strike Deltas
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            <Strikes backtest={backtestPerformance} />
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Strike Width
          </div>
          <div className="mt-0.5 flex flex-row items-center text-lg font-semibold">
            <StrikeWidth backtest={backtestPerformance} />
          </div>
        </div>
        <div className="mr-3 mt-3 flex-none rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 pb-2 pt-3">
          <div className="text-xs text-slate-500 dark:text-zinc-300">
            Spread / Stock %
          </div>
          <div className="mt-0.5 text-lg font-semibold">
            {backtestPerformance.spreadYieldTarget ? (
              <div className="flex flex-row items-center">
                <div>{round2(backtestPerformance.spreadYieldTarget * 100)}</div>
                <ShowLevel level={backtestPerformance.spreadYieldLevel} />
              </div>
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
        <EntryTriggers backtest={backtestPerformance} />
        <ExitTriggers backtest={backtestPerformance} />
      </div>
      <div className="relative mt-5">
        <div className="absolute mt-[1.63rem] h-px w-full bg-slate-200 dark:bg-zinc-600"></div>
        <div className="scrollbar absolute flex flex-row space-x-8 overflow-x-auto border-b border-slate-200 dark:border-zinc-600">
          {["Graph", "Performance Metrics", "Monthly Returns", "Trade Log"].map(
            tab => (
              <div
                key={tab}
                className={
                  "flex-none cursor-pointer font-medium hover:text-black" +
                  (selectedTab === tab
                    ? " border-b-2 text-black dark:text-white border-black dark:border-white"
                    : " text-slate-500 dark:text-zinc-400 hover:text-black dark:hover:text-white")
                }
                onClick={() => {
                  setSelectedTab(tab)
                }}
              >
                {tab}
              </div>
            )
          )}
        </div>
      </div>
      <div className="scrollbar mt-7 flex min-h-[38rem] lg:min-h-0 flex-grow flex-col lg:overflow-y-auto">
        {selectedTab === "Graph" ? (
          graphData?.length > 0 && (
            <>
              <div className="mt-1 flex flex-row flex-wrap items-center text-sm">
                <div className="flex flex-row items-center mt-3">
                  <div className="mr-3">Starting capital: </div>
                  <Listbox
                    value={startingCapital}
                    onChange={setStartingCapital}
                    className="relative z-20"
                    as="div"
                  >
                    <ListboxButton className="mr-8 flex-start flex w-32 items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                      <div>{"$" + startingCapital.toLocaleString()}</div>
                      <FontAwesomeIcon
                        className="ml-auto"
                        icon={faChevronDown}
                      />
                    </ListboxButton>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <ListboxOptions className="scrollbar absolute mt-1.5 w-full overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                        {[100000, 200000, 500000, 1000000].map(
                          (amount, key) => (
                            <ListboxOption
                              key={key}
                              value={amount}
                              as={Fragment}
                            >
                              {({ active, selected }) => (
                                <div
                                  className={
                                    "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                    (active
                                      ? " bg-slate-200/70 dark:bg-zinc-700"
                                      : "")
                                  }
                                >
                                  {"$" + amount.toLocaleString()}
                                </div>
                              )}
                            </ListboxOption>
                          )
                        )}
                      </ListboxOptions>
                    </Transition>
                  </Listbox>
                </div>
                <div className="flex flex-row items-center mt-3">
                  <div className="mr-2">Compounded:</div>
                  <Switch
                    checked={compounding}
                    onChange={setCompounding}
                    className={`${
                      compounding
                        ? "bg-orange-600"
                        : "bg-slate-300 dark:bg-zinc-700"
                    } relative inline-flex h-6 w-11 flex-none mr-8 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        compounding ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                <div className="flex flex-row items-center mt-3">
                  <div className="mr-3">
                    Percent of {compounding ? "total" : "starting"} capital to
                    be used on each strategy trade:
                  </div>
                  <Listbox
                    value={capAlloc}
                    onChange={setCapAlloc}
                    className="relative"
                    as="div"
                  >
                    <ListboxButton className="flex-start flex w-24 items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 text-sm font-medium ring-0 focus:outline-none focus:ring-0">
                      <div>{capAlloc * 100 + "%"}</div>
                      <FontAwesomeIcon
                        className="ml-auto"
                        icon={faChevronDown}
                      />
                    </ListboxButton>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <ListboxOptions className="scrollbar absolute mt-1.5 w-full overflow-auto rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-0.5 py-0.5 shadow-lg ring-0 focus:outline-none focus:ring-0">
                        {[
                          0.01, 0.02, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5,
                          0.75, 1
                        ].map((amount, key) => (
                          <ListboxOption key={key} value={amount} as={Fragment}>
                            {({ active, selected }) => (
                              <div
                                className={
                                  "cursor-pointer select-none rounded-md px-2 py-2 text-sm hover:bg-slate-200/70 dark:hover:bg-zinc-700" +
                                  (active
                                    ? " bg-slate-200/70 dark:bg-zinc-700"
                                    : "")
                                }
                              >
                                {amount * 100 + "%"}
                              </div>
                            )}
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </Transition>
                  </Listbox>
                </div>
              </div>
              <div className="pt-3 text-sm font-normal italic">
                Returns are calculated using margin and are adjusted for
                commisions and dividends.
              </div>
              <Graph data={graphData} />
            </>
          )
        ) : selectedTab == "Performance Metrics" ? (
          <PerformanceMetrics data={backtestPerformance} />
        ) : selectedTab == "Monthly Returns" ? (
          <MonthlyReturns data={backtestReturns.monthly} />
        ) : selectedTab == "Trade Log" ? (
          <TradeLog
            data={backtestReturns.trades}
            strategy={backtestPerformance?.strategy}
          />
        ) : (
          <div></div>
        )}
      </div>
    </>
  ) : (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="italic text-slate-600 dark:text-zinc-300">
        Loading backtest details
      </div>
      <FontAwesomeIcon
        icon={faCircleNotch}
        className="spinner mt-3 text-2xl text-slate-600 dark:text-zinc-300"
        aria-hidden="true"
      />
    </div>
  )
}

function convertToDate(date) {
  let dateArray = date.split("-")
  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
}

function DTE({ backtest }) {
  let isSameDte = true

  if (
    backtest.dte2Target !== null &&
    backtest.dte1Target !== backtest.dte2Target
  ) {
    isSameDte = false
  }
  if (
    backtest.dte3Target !== null &&
    backtest.dte1Target !== backtest.dte3Target
  ) {
    isSameDte = false
  }
  if (
    backtest.dte4Target !== null &&
    backtest.dte1Target !== backtest.dte4Target
  ) {
    isSameDte = false
  }

  if (isSameDte) {
    return <>{backtest.dte1Target && <div>{backtest.dte1Target}</div>}</>
  } else {
    return (
      <>
        {backtest.dte1Target && <div>{backtest.dte1Target}</div>}
        {backtest.dte2Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.dte2Target}
          </div>
        )}
        {backtest.dte3Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.dte3Target}
          </div>
        )}
        {backtest.dte4Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.dte4Target}
          </div>
        )}
      </>
    )
  }
}

function DTEDiff({ backtest }) {
  return (
    <>
      {backtest.dte2Target && (
        <div>
          {" "}
          {(backtest.lrDteLeg1Leg2Min === null
            ? "–"
            : backtest.lrDteLeg1Leg2Min) +
            "/" +
            (backtest.lrDteLeg1Leg2Max === null
              ? "–"
              : backtest.lrDteLeg1Leg2Max)}
        </div>
      )}
      {backtest.dte3Target && (
        <div>
          <FontAwesomeIcon
            icon={faPipe}
            className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
          />{" "}
          {(backtest.lrDteLeg2Leg3Min === null
            ? "–"
            : backtest.lrDteLeg2Leg3Min) +
            "/" +
            (backtest.lrDteLeg2Leg3Max === null
              ? "–"
              : backtest.lrDteLeg2Leg3Max)}
        </div>
      )}
      {backtest.dte4Target && (
        <div>
          <FontAwesomeIcon
            icon={faPipe}
            className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
          />{" "}
          {(backtest.lrDteLeg3Leg4Min === null
            ? "–"
            : backtest.lrDteLeg3Leg4Min) +
            "/" +
            (backtest.lrDteLeg3Leg4Max === null
              ? "–"
              : backtest.lrDteLeg3Leg4Max)}
        </div>
      )}
    </>
  )
}

function Strikes({ backtest }) {
  let isSameStrikes = true

  if (
    backtest.delta2Target !== null &&
    backtest.delta1Target !== backtest.delta2Target
  ) {
    isSameStrikes = false
  }
  if (
    backtest.delta3Target !== null &&
    backtest.delta1Target !== backtest.delta3Target
  ) {
    isSameStrikes = false
  }
  if (
    backtest.delta4Target !== null &&
    backtest.delta1Target !== backtest.delta4Target
  ) {
    isSameStrikes = false
  }

  if (isSameStrikes) {
    return (
      <>
        {backtest.delta1Target && <div>{backtest.delta1Target.toFixed(2)}</div>}
      </>
    )
  } else {
    return (
      <>
        {backtest.delta1Target && <div>{backtest.delta1Target.toFixed(2)}</div>}
        {backtest.delta2Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.delta2Target.toFixed(2)}
          </div>
        )}
        {backtest.delta3Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.delta3Target.toFixed(2)}
          </div>
        )}
        {backtest.delta4Target && (
          <div>
            <FontAwesomeIcon
              icon={faPipe}
              className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
            />{" "}
            {backtest.delta4Target.toFixed(2)}
          </div>
        )}
      </>
    )
  }
}

function StrikeWidth({ backtest }) {
  return (
    <>
      {backtest.delta2Target && (
        <div>
          {(backtest.lrSwLeg1Leg2Min === null
            ? "–"
            : backtest.lrSwLeg1Leg2Min) +
            "/" +
            (backtest.lrSwLeg1Leg2Max === null
              ? "–"
              : backtest.lrSwLeg1Leg2Max)}
        </div>
      )}
      {backtest.delta3Target && (
        <div>
          <FontAwesomeIcon
            icon={faPipe}
            className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
          />{" "}
          {(backtest.lrSwLeg2Leg3Min === null
            ? "–"
            : backtest.lrSwLeg2Leg3Min) +
            "/" +
            (backtest.lrSwLeg2Leg3Max === null
              ? "–"
              : backtest.lrSwLeg2Leg3Max)}
        </div>
      )}
      {backtest.delta4Target && (
        <div>
          <FontAwesomeIcon
            icon={faPipe}
            className="ml-1.5 mr-1 text-slate-300 dark:text-zinc-300"
          />{" "}
          {(backtest.lrSwLeg3Leg4Min === null
            ? "–"
            : backtest.lrSwLeg3Leg4Min) +
            "/" +
            (backtest.lrSwLeg3Leg4Max === null
              ? "–"
              : backtest.lrSwLeg3Leg4Max)}
        </div>
      )}
    </>
  )
}

function EntryTriggers({ backtest }) {
  let arr = []
  if (backtest.entryVix !== "none") {
    arr.push(
      "VIX is " + getLevel({ backtest: backtest, indicator: "entryVix" })
    )
  }
  if (backtest.entrySma !== "none") {
    arr.push(
      "Stock Price is " +
        getLevel({ backtest: backtest, indicator: "entrySma" }) +
        " SMA"
    )
  }
  if (backtest.entryRsi !== "none") {
    arr.push(
      "14d RSI is " + getLevel({ backtest: backtest, indicator: "entryRsi" })
    )
  }
  if (backtest.entryIvPct !== "none") {
    arr.push(
      "IV Percentile 1y is " +
        getLevel({ backtest: backtest, indicator: "entryIvPct" })
    )
  }
  if (backtest.entrySlopePct !== "none") {
    arr.push(
      "Slope Percentile 1y is " +
        getLevel({ backtest: backtest, indicator: "entrySlopePct" })
    )
  }
  if (arr.length == 0) {
    return null
  } else {
    return (
      <div className="mr-6 mt-2 flex-none rounded-lg pt-1.5">
        <div className="text-xs text-slate-500 dark:text-zinc-300">
          Entry Triggers
        </div>
        <div className="mt-1.5 flex flex-row space-x-2 text-lg">
          {arr.map((item, index) => (
            <div
              key={index}
              className="rounded-full bg-slate-100/80 dark:bg-zinc-700 px-3.5 py-2 text-sm font-semibold"
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

function ExitTriggers({ backtest }) {
  let arr = []
  if (backtest.exitStopLoss !== "none") {
    arr.push(
      getLevel({ backtest: backtest, indicator: "exitStopLoss" }) + " Stop Loss"
    )
  }
  if (backtest.exitProfit !== "none") {
    arr.push(
      getLevel({ backtest: backtest, indicator: "exitProfit" }) +
        " Profit Target"
    )
  }
  if (arr.length == 0) {
    return null
  } else {
    return (
      <div className="mr-6 mt-2 flex-none rounded-lg pt-1.5">
        <div className="text-xs text-slate-500 dark:text-zinc-300">
          Exit Triggers
        </div>
        <div className="mt-1.5 flex flex-row space-x-2 text-lg">
          {arr.map((item, index) => (
            <div
              key={index}
              className="rounded-full bg-slate-100/80 dark:bg-zinc-700 px-3.5 py-2 text-sm font-semibold"
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

function FindSimilar({ backtest, setSelectedBacktestId }) {
  const router = useRouter()
  const pathname = usePathname()

  const [filters, setFilters] = useAtom(btFiltersAtom)
  const [strategy, setStrategy] = useAtom(btStrategyAtom)
  const [ticker, setTicker] = useAtom(btTickerAtom)
  const [currentEnvironmentToggle, setCurrentEnvironmentToggle] = useAtom(
    btCurrentEnvironmentToggleAtom
  )
  const [dte, setDte] = useState(true)
  const [strikes, setStrikes] = useState(true)
  const [spreadYield, setSpreadYield] = useState(true)
  const [dteDiff, setDteDiff] = useState(true)
  const [strikeWidth, setStrikeWidth] = useState(true)
  const [entryTriggers, setEntryTriggers] = useState(true)
  const [exitTriggers, setExitTriggers] = useState(true)

  return (
    <Popover className="relative flex-none mr-4 mt-3">
      {({ open }) => (
        <>
          <PopoverButton
            className={
              "flex w-60 flex-row items-center rounded-md border border-slate-300 dark:border-zinc-500 bg-white dark:bg-zinc-800 px-2.5 py-2 font-medium ring-0 focus:outline-none focus:ring-0"
            }
          >
            Find similar backtests
            <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
          </PopoverButton>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <PopoverPanel className="absolute z-50 mt-1.5 w-60 rounded-md border border-slate-300 dark:border-zinc-500 dark:bg-zinc-800 bg-white p-4 shadow-lg">
              <div className="text-sm font-normal italic">
                Find similar backtests by selecting which inputs you want to
                stay constant. Doing so will auto-apply the filters and take you
                back to the table.
              </div>
              <div
                className="mt-4 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setDte(!dte)
                }}
              >
                <FontAwesomeIcon
                  icon={dte ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">DTE</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setStrikes(!strikes)
                }}
              >
                <FontAwesomeIcon
                  icon={strikes ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">Strike Deltas</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setDteDiff(!dteDiff)
                }}
              >
                <FontAwesomeIcon
                  icon={dteDiff ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">DTE Diff</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setStrikeWidth(!strikeWidth)
                }}
              >
                <FontAwesomeIcon
                  icon={strikeWidth ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">Strike Width</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setSpreadYield(!spreadYield)
                }}
              >
                <FontAwesomeIcon
                  icon={spreadYield ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">Spread / Stock %</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setEntryTriggers(!entryTriggers)
                }}
              >
                <FontAwesomeIcon
                  icon={entryTriggers ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">Entry Triggers</div>
              </div>
              <div
                className="mt-2 flex max-w-fit cursor-pointer select-none flex-row items-center"
                onClick={() => {
                  setExitTriggers(!exitTriggers)
                }}
              >
                <FontAwesomeIcon
                  icon={exitTriggers ? faSquareCheck : faSquare}
                  className="mr-2"
                />
                <div className="text-sm">Exit Triggers</div>
              </div>
              <div
                className="mt-4 w-full cursor-pointer rounded-md border border-orange-600 py-2 text-center text-sm text-orange-600 hover:bg-orange-600/10"
                onClick={() => {
                  let _filters = cloneDeep(filters)

                  // Set all entry filters to default
                  _filters.dte1Target = {
                    min: null,
                    max: null
                  }
                  _filters.dte2Target = {
                    min: null,
                    max: null
                  }
                  _filters.dte3Target = {
                    min: null,
                    max: null
                  }
                  _filters.dte4Target = {
                    min: null,
                    max: null
                  }
                  _filters.delta1Target = {
                    min: null,
                    max: null
                  }
                  _filters.delta2Target = {
                    min: null,
                    max: null
                  }
                  _filters.delta3Target = {
                    min: null,
                    max: null
                  }
                  _filters.delta4Target = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg1Leg2Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg1Leg2Max = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg2Leg3Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg2Leg3Max = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg3Leg4Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrDteLeg3Leg4Max = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg1Leg2Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg1Leg2Max = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg2Leg3Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg2Leg3Max = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg3Leg4Min = {
                    min: null,
                    max: null
                  }
                  _filters.lrSwLeg3Leg4Max = {
                    min: null,
                    max: null
                  }
                  _filters.spreadYieldLevel = null
                  _filters.entryVix = null
                  _filters.entrySma = null
                  _filters.entryRsi = null
                  _filters.entryIvPct = null
                  _filters.entrySlopePct = null
                  _filters.exitStopLoss = null
                  _filters.exitProfit = null

                  // Set filters based on what was selected
                  if (dte) {
                    _filters.dte1Target = {
                      min: backtest.dte1Target,
                      max: backtest.dte1Target
                    }
                    _filters.dte2Target = {
                      min: backtest.dte2Target,
                      max: backtest.dte2Target
                    }
                    _filters.dte3Target = {
                      min: backtest.dte3Target,
                      max: backtest.dte3Target
                    }
                    _filters.dte4Target = {
                      min: backtest.dte4Target,
                      max: backtest.dte4Target
                    }
                  }
                  if (strikes) {
                    _filters.delta1Target = {
                      min: backtest.delta1Target,
                      max: backtest.delta1Target
                    }
                    _filters.delta2Target = {
                      min: backtest.delta2Target,
                      max: backtest.delta2Target
                    }
                    _filters.delta3Target = {
                      min: backtest.delta3Target,
                      max: backtest.delta3Target
                    }
                    _filters.delta4Target = {
                      min: backtest.delta4Target,
                      max: backtest.delta4Target
                    }
                  }
                  if (dteDiff) {
                    _filters.lrDteLeg1Leg2Min = {
                      min: backtest.lrDteLeg1Leg2Min,
                      max: backtest.lrDteLeg1Leg2Min
                    }
                    _filters.lrDteLeg1Leg2Max = {
                      min: backtest.lrDteLeg1Leg2Max,
                      max: backtest.lrDteLeg1Leg2Max
                    }
                    _filters.lrDteLeg2Leg3Min = {
                      min: backtest.lrDteLeg2Leg3Min,
                      max: backtest.lrDteLeg2Leg3Min
                    }
                    _filters.lrDteLeg2Leg3Max = {
                      min: backtest.lrDteLeg2Leg3Max,
                      max: backtest.lrDteLeg2Leg3Max
                    }
                    _filters.lrDteLeg3Leg4Min = {
                      min: backtest.lrDteLeg3Leg4Min,
                      max: backtest.lrDteLeg3Leg4Min
                    }
                    _filters.lrDteLeg3Leg4Max = {
                      min: backtest.lrDteLeg3Leg4Max,
                      max: backtest.lrDteLeg3Leg4Max
                    }
                  }
                  if (strikeWidth) {
                    _filters.lrSwLeg1Leg2Min = {
                      min: backtest.lrSwLeg1Leg2Min,
                      max: backtest.lrSwLeg1Leg2Min
                    }
                    _filters.lrSwLeg1Leg2Max = {
                      min: backtest.lrSwLeg1Leg2Max,
                      max: backtest.lrSwLeg1Leg2Max
                    }
                    _filters.lrSwLeg2Leg3Min = {
                      min: backtest.lrSwLeg2Leg3Min,
                      max: backtest.lrSwLeg2Leg3Min
                    }
                    _filters.lrSwLeg2Leg3Max = {
                      min: backtest.lrSwLeg2Leg3Max,
                      max: backtest.lrSwLeg2Leg3Max
                    }
                    _filters.lrSwLeg3Leg4Min = {
                      min: backtest.lrSwLeg3Leg4Min,
                      max: backtest.lrSwLeg3Leg4Min
                    }
                    _filters.lrSwLeg3Leg4Max = {
                      min: backtest.lrSwLeg3Leg4Max,
                      max: backtest.lrSwLeg3Leg4Max
                    }
                  }
                  if (spreadYield) {
                    _filters.spreadYieldLevel = backtest.spreadYieldLevel
                  }
                  if (entryTriggers) {
                    _filters.entryVix = backtest.entryVix
                    _filters.entrySma = backtest.entrySma
                    _filters.entryRsi = backtest.entryRsi
                    _filters.entryIvPct = backtest.entryIvPct
                    _filters.entrySlopePct = backtest.entrySlopePct
                  }
                  if (exitTriggers) {
                    _filters.exitStopLoss = backtest.exitStopLoss
                    _filters.exitProfit = backtest.exitProfit
                  }

                  // Reset ticker, strategy, and current environment
                  setTicker(backtest.ticker)
                  setStrategy(
                    strategies.find(
                      strategy => strategy.id == backtest.strategy
                    )
                  )
                  setCurrentEnvironmentToggle(false)
                  setFilters(_filters)

                  if (pathname.includes("all-backtests") == false) {
                    router.push("/all-backtests")
                  } else {
                    setSelectedBacktestId(null)
                  }
                }}
              >
                Find similar backtests
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
