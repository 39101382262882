import { useState, useEffect, useRef } from "react"
import { scaleLinear, scaleBand } from "@visx/scale"
import { Line, LinePath, Bar } from "@visx/shape"
import { AxisLeft, AxisBottom } from "@visx/axis"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { useTooltip, Tooltip, defaultStyles } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import { format } from "date-fns"
import { useAtom } from "jotai"
import { themeAtom } from "/lib/store"

export default function Graph({ data }) {
  const [theme, setTheme] = useAtom(themeAtom)

  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)
  const wrapperRef = useRef(null)
  function handleResize() {
    setWidth(wrapperRef?.current?.offsetWidth)
  }
  useEffect(() => {
    setHeight(Math.max(wrapperRef?.current?.offsetHeight - 10, 350))
    handleResize()
    window.addEventListener("resize", handleResize)
    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  }, [data])

  const margin = { top: 15, bottom: 70, left: 70, right: 50 }
  const xScale = scaleBand({
    domain: data?.map(d => d.date),
    range: [margin.left, width - margin.right]
  })

  const yScale = scaleLinear({
    domain: [
      Math.min(
        ...data.map(d => d.stockReturn),
        ...data.map(d => d.strategyReturn)
      ),
      Math.max(
        ...data.map(d => d.stockReturn),
        ...data.map(d => d.strategyReturn)
      )
    ],
    range: [height - margin.bottom, margin.top]
  })

  const stockColor = "#2563eb"
  const strategyColor = "#ea580c"
  const axisColor = theme == "dark" ? "#d4d4d8" : "#334155"

  // Tooltip
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip()
  const handleTooltip = (event, datum) => {
    const coords = localPoint(event.target.ownerSVGElement, event)

    let eachBand = xScale.step()
    let index = Math.min(
      Math.round((coords.x - margin.left) / eachBand),
      data?.length - 1
    )
    let dateVal = xScale.domain()[index]
    if (index > -1) {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: {
          xVal: dateVal,
          yVal: yScale.invert(coords.y),
          index: index
        }
      })
    }
  }

  return (
    <>
      <div className="flex flex-row items-center flex-wrap text-sm">
        <div className="flex-none flex flex-row items-center rounded-full bg-blue-600/10 px-4 py-2 mr-4 mt-3">
          <div
            className="h-2 w-2 flex-none rounded-full"
            style={{ background: stockColor }}
          ></div>
          <div className="ml-2">Stock Return</div>
          <div className="ml-2 font-semibold">
            {"$" +
              getValue({
                id: "stockReturn",
                data: data,
                index: tooltipData?.index
              }).toLocaleString()}
          </div>
        </div>
        <div className="mt-3 flex-none flex flex-row items-center rounded-full bg-orange-600/10 px-4 py-2">
          <div
            className="h-2 w-2 flex-none rounded-full"
            style={{ background: strategyColor }}
          ></div>
          <div className="ml-2">Strategy Return</div>
          <div className="ml-2 font-semibold">
            {"$" +
              getValue({
                id: "strategyReturn",
                data: data,
                index: tooltipData?.index
              }).toLocaleString()}
          </div>
        </div>
      </div>
      <div
        ref={wrapperRef}
        className="min-h-0 w-full flex-grow cursor-crosshair"
      >
        <svg width={width} height={height}>
          <GridRows
            scale={yScale}
            width={width - margin.left - margin.right}
            left={margin.left}
            strokeDasharray="4,4"
            stroke={theme == "dark" ? "#71717a" : "#e2e8f0"}
            numTicks={6}
          />
          <AxisLeft
            scale={yScale}
            left={margin.left}
            hideTicks={true}
            tickLabelProps={d => ({
              textAnchor: "end",
              fontSize: "12px",
              fontWeight: 500,
              fill: axisColor,
              dx: -3,
              dy: 4
            })}
            stroke={axisColor}
            numTicks={6}
            tickLength={4}
          />
          <AxisBottom
            scale={xScale}
            stroke={axisColor}
            top={height - margin.bottom}
            tickValues={getXAxisTickValues(data, width)}
            hideTicks={false}
            tickLength={4}
            tickLabelProps={d => ({
              textAnchor: "middle",
              fontSize: "12px",
              fill: axisColor,
              fontWeight: 500,
              dy: 4
            })}
            tickFormat={d => {
              return format(d, "MMM yyyy")
            }}
          />
          <LinePath
            data={data}
            x={d => xScale(d.date)}
            y={d => yScale(d.stockReturn)}
            stroke={stockColor}
            strokeWidth={1.5}
            strokeLinecap="round"
          />
          <LinePath
            data={data}
            x={d => xScale(d.date)}
            y={d => yScale(d.strategyReturn)}
            stroke={strategyColor}
            strokeWidth={1.5}
            strokeLinecap="round"
          />

          <Group left={margin.left} top={margin.top}>
            <Bar
              width={width - margin.right - margin.left}
              height={height - margin.bottom}
              fill="transparent"
              rx={0}
              onMouseMove={handleTooltip}
              onMouseOut={hideTooltip}
            />
          </Group>
          {tooltipData && (
            <g>
              <Line
                from={{
                  x: xScale(data[tooltipData.index]?.date),
                  y: margin.top
                }}
                to={{
                  x: xScale(data[tooltipData.index]?.date),
                  y: height - margin.bottom
                }}
                stroke={axisColor}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="4,3"
              />
              <Group
                left={xScale(data[tooltipData.index]?.date)}
                top={yScale(data[tooltipData.index]?.stockReturn)}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={5}
                  fill={stockColor}
                  pointerEvents="none"
                />
                <foreignObject x={-168} y={-14} width={160} height={28}>
                  <div
                    className="flex h-full flex-row rounded px-2 pt-1.5 text-xs text-white"
                    style={{
                      background: stockColor
                    }}
                  >
                    <div>Stock:</div>
                    <div className="ml-1 flex-none">
                      {"$" +
                        Math.round(
                          data[tooltipData.index]?.stockReturn
                        ).toLocaleString()}
                    </div>
                  </div>
                </foreignObject>
              </Group>
              <Group
                left={xScale(data[tooltipData.index]?.date)}
                top={yScale(data[tooltipData.index]?.strategyReturn)}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={5}
                  fill={strategyColor}
                  pointerEvents="none"
                />
                <foreignObject x={-168} y={-14} width={160} height={28}>
                  <div
                    className="flex h-full flex-row rounded px-2 pt-1.5 text-xs text-white"
                    style={{
                      background: strategyColor
                    }}
                  >
                    <div>Strategy:</div>
                    <div className="ml-1 flex-none">
                      {"$" +
                        Math.round(
                          data[tooltipData.index]?.strategyReturn
                        ).toLocaleString()}
                    </div>
                  </div>
                </foreignObject>
              </Group>
            </g>
          )}
        </svg>
        {tooltipOpen && (
          <Tooltip
            key={Math.random()}
            top={
              height +
              wrapperRef?.current.getBoundingClientRect().top -
              margin.bottom -
              5
            }
            left={wrapperRef?.current.offsetLeft + tooltipLeft - 10}
            style={{
              ...defaultStyles,
              borderRadius: "4px",
              background: axisColor,
              textAlign: "center",
              width: "90px",
              color: theme == "dark" ? "#000000" : "#ffffff",
              fontSize: "12px",
              paddingLeft: "12px",
              paddingRight: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
              transform: "translateX(-50%)"
            }}
          >
            <div>{format(tooltipData.xVal, "MMM yyyy")}</div>
          </Tooltip>
        )}
      </div>
    </>
  )
}

function getValue({ id, data, index }) {
  if (data?.length > 0) {
    if (index !== undefined) {
      return Math.round(data[index][id])
    } else {
      return Math.round(data[data.length - 1][id])
    }
  }
}

function getXAxisTickValues(data, width) {
  let divisor = 5
  if (width < 300) {
    divisor = 2
  } else if (width < 450) {
    divisor = 3
  } else if (width < 700) {
    divisor = 5
  } else {
    divisor = 7
  }
  const tickValues = []
  const totalDays = data.length
  const daysPerTick = Math.max(1, Math.floor(totalDays / divisor))
  for (let i = 0; i < totalDays; i += daysPerTick) {
    tickValues.push(data[i].date)
  }
  return tickValues
}
