import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useBacktestInputs({ baseId, custom }) {
  return useQuery(
    ["backtestInputs", baseId, custom],
    () =>
      fetchBacktestInputs({
        baseId,
        custom
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )
}

const fetchBacktestInputs = async ({ baseId, custom }) => {
  if (custom) {
    const { data } = await axios.get("/api/orats/backtests/custom/input", {
      params: { id: baseId }
    })
    return data
  } else {
    const { data } = await axios.get("/api/orats/backtests/input", {
      params: { id: baseId }
    })
    return data
  }
}
