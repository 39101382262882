import cx from "classnames"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { useState } from "react"

const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  className,
  side = "bottom",
  usePortal = false
}) => {
  const _content = (
    <TooltipPrimitive.Content
      side={side}
      sideOffset={4}
      className={cx(
        "radix-side-bottom:animate-slide-up-fade",
        "radix-side-top:animate-slide-down-fade",
        "radix-side-right:animate-slide-left-fade",
        "radix-side-bottom:animate-slide-up-fade",
        "radix-side-left:animate-slide-right-fade",
        className
      )}
    >
      <TooltipPrimitive.Arrow className="fill-current" />
      {content}
    </TooltipPrimitive.Content>
  )

  const [openTooltip, setOpenTooltip] = useState(open)

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={100}
        open={openTooltip}
        defaultOpen={defaultOpen}
        onOpenChange={setOpenTooltip}
      >
        <TooltipPrimitive.Trigger
          asChild
          onClick={() => setOpenTooltip(prevOpen => !prevOpen)}
          onFocus={() => setTimeout(() => setOpenTooltip(true), 0)} // timeout needed to run this after onOpenChange to prevent bug on mobile
          onBlur={() => setOpenTooltip(false)}
        >
          {children}
        </TooltipPrimitive.Trigger>
        {usePortal ? (
          <TooltipPrimitive.Portal
            container={document.getElementById("radixPortal")}
          >
            {_content}
          </TooltipPrimitive.Portal>
        ) : (
          _content
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
