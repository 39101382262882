import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useAvailableStrategies() {
  return useQuery(["allBacktests"], () => fetchAvailableStrategies(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

const fetchAvailableStrategies = async () => {
  const { data } = await axios.get("/api/orats/backtests")
  return data
}
