import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useStockReturns({ ticker }) {
  return useQuery(
    ["stockReturns", ticker],
    () =>
      fetchStockReturns({
        ticker
      }),
    {
      enabled: !!ticker,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )
}

const fetchStockReturns = async ({ ticker }) => {
  const { data } = await axios.get("/api/orats/datav2/hist/dailies", {
    params: { ticker }
  })
  return data
}
