import { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleInfo,
  faTrashAlt,
  faCircleNotch
} from "@fortawesome/pro-solid-svg-icons"
import { Dialog, DialogPanel } from "@headlessui/react"
import Tooltip from "./Tooltip"
import BacktestDetails from "./BacktestDetails"
import ShowLevel from "./ShowLevel"
import {
  performanceColumns,
  formatPerformanceMetric,
  getLevel,
  round2,
  DTE,
  Strikes
} from "./Shared.js"
import { useRouter } from "next/navigation"
import useUserData from "/hooks/useUserData"
import { useQueryClient } from "react-query"
import axios from "@/lib/axios"
import { useAtom } from "jotai"
import { userIdAtom } from "/lib/store"

export default function MyFavorites() {
  const queryClient = useQueryClient()
  const [userId, setUserId] = useAtom(userIdAtom)
  const { data: userData, isFetching: isFetchingUserData } = useUserData({
    email: userId
  })

  const router = useRouter()
  const [selectedBacktestId, setSelectedBacktestId] = useState(null)
  const scrollRef = useRef(null)

  // Update URL with id
  useEffect(() => {
    if (selectedBacktestId !== null) {
      router.push("/my-favorites?id=" + selectedBacktestId)
    } else {
      router.push("/my-favorites")
    }
  }, [selectedBacktestId])

  // Load from URL
  useEffect(() => {
    if (router.query?.id) {
      setSelectedBacktestId(router.query.id)
    }
  }, [router.query])

  return (
    <>
      <Dialog
        open={selectedBacktestId !== null}
        onClose={() => setSelectedBacktestId(null)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center bg-slate-900/20 dark:bg-zinc-900/80 md:p-14 p-5 font-medium dark:text-white">
          <DialogPanel className="scrollbar my-14 flex h-full w-full flex-col overflow-scroll rounded-xl bg-white dark:bg-zinc-800 px-6 pt-3 shadow-xl dark:border-2 dark:border-zinc-500">
            <BacktestDetails
              selectedBacktestId={selectedBacktestId}
              setSelectedBacktestId={setSelectedBacktestId}
              custom={false}
            />
          </DialogPanel>
        </div>
      </Dialog>
      <div className="min-h-0 flex flex-col">
        <div className="min-h-0 flex flex-col">
          <div className="pb-2 pt-5 px-3 text-sm">
            Showing{" "}
            {userData?.backtestFavorites?.length > 0
              ? userData?.backtestFavorites?.length
              : 0}{" "}
            backtest
            {userData?.backtestFavorites?.length == 1 ? "" : "s"}
          </div>
          <div className="min-h-0 max-w-fit pb-6 pt-2 px-3">
            <div
              className="scrollbar flex max-h-full flex-col overflow-y-auto overflow-x-auto rounded-md border border-slate-300 dark:border-zinc-500"
              ref={scrollRef}
            >
              <div className="sticky top-0 z-10 flex flex-row flex-nowrap rounded-tl-md rounded-tr-md text-sm">
                <div className="w-12 flex-none border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4"></div>
                <div className="border-b border-r border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4">
                  <div className="text-orange-600">Backtest Inputs</div>
                  <div className="mt-5 flex flex-row">
                    <div className="mr-6 w-16">Symbol</div>
                    <div className="mr-6 w-32">Strategy</div>
                    <div className="mr-6 w-36">Days to Expiration</div>
                    <div className="mr-6 w-48">Strike Deltas</div>
                    <div className="mr-6 w-36">Spread / Stock %</div>
                    <div className="w-52">Entry Triggers</div>
                    <div className="w-36">Exit Triggers</div>
                  </div>
                </div>
                <div className="border-b border-slate-300 dark:border-zinc-500 bg-slate-50 dark:bg-zinc-900 px-4 pb-3 pt-4">
                  <div className="text-blue-600 dark:text-blue-500">
                    Performance
                  </div>
                  <div className="flex flex-row">
                    {performanceColumns.map(col => (
                      <div
                        className={
                          "mr-6 w-44 " + (col.subtitle ? "mt-1" : "mt-5")
                        }
                        key={col.id}
                      >
                        <div className="truncate">{col.name}</div>
                        {col.subtitle && (
                          <div className="flex flex-row items-center text-xs font-normal italic">
                            <div>{col.subtitle}</div>
                            <Tooltip
                              content={
                                <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal text-white shadow-md">
                                  {col.description}
                                </div>
                              }
                            >
                              <FontAwesomeIcon
                                className="ml-1.5 cursor-pointer text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                                icon={faCircleInfo}
                                aria-hidden="true"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex-grow">
                {isFetchingUserData ? (
                  <div className="my-36 flex h-full w-full flex-col items-center justify-center">
                    <div className="italic text-slate-600 dark:text-zinc-300">
                      Fetching my favorites
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="spinner mt-3 text-2xl text-slate-600 dark:text-zinc-300"
                      aria-hidden="true"
                    />
                  </div>
                ) : userData?.backtestFavorites?.length == 0 ? (
                  <div
                    className="relative my-36 flex h-full w-full flex-col items-center justify-center"
                    style={{
                      left: scrollRef.current?.scrollLeft
                    }}
                  >
                    <div className="px-6 italic text-slate-600 dark:text-zinc-300">
                      No favorites yet. Click the &quot;Save to My
                      Favorites&quot; button in the top right of the backtest
                      details popup to add a backtest to your favorites.
                    </div>
                  </div>
                ) : (
                  userData?.backtestFavorites?.map((backtest, index) => (
                    <div
                      key={backtest.id}
                      className="group flex cursor-pointer flex-row flex-nowrap text-sm"
                      onClick={() => {
                        setSelectedBacktestId(backtest.id)
                      }}
                    >
                      <div className="flex w-12 flex-none flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        <div
                          className="flex w-12 flex-row items-center text-red-600"
                          onClick={async e => {
                            e.stopPropagation()
                            await axios.delete(
                              "/api/orats/backtests/favorites",
                              {
                                params: {
                                  email: userId,
                                  backtestId: backtest?.id
                                }
                              }
                            )
                            queryClient.invalidateQueries("userData")
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                      </div>
                      <div className="flex flex-row border-b border-r dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        <div className="mr-6 flex w-16 flex-row items-center">
                          {backtest.ticker}
                        </div>
                        <div className="mr-6 flex w-32 flex-row items-center">
                          {backtest.strategy.replace(/([A-Z])/g, " $1").trim()}
                        </div>
                        <div className="mr-6 flex w-36 flex-row items-center">
                          <DTE backtest={backtest} />
                        </div>
                        <div className="mr-6 flex w-48 flex-row items-center">
                          <Strikes backtest={backtest} />
                        </div>
                        <div className="mr-6 flex w-36 flex-row items-center">
                          {backtest.spreadYieldTarget ? (
                            <div className="flex flex-row items-center">
                              <div>
                                {round2(backtest.spreadYieldTarget * 100)}
                              </div>
                              <ShowLevel level={backtest.spreadYieldLevel} />
                            </div>
                          ) : (
                            <div>None</div>
                          )}
                        </div>
                        <div className="w-52">
                          {backtest.entryVix !== "none" &&
                            backtest.entryVix !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"VIX is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryVix"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entrySma !== "none" &&
                            backtest.entrySma !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"Stock Price is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entrySma"
                                    }) +
                                    " SMA"}
                                </div>
                              </div>
                            )}
                          {backtest.entryRsi !== "none" &&
                            backtest.entryRsi !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"14d RSI is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryRsi"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entryIvPct !== "none" &&
                            backtest.entryIvPct !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"IV Percentile 1y is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entryIvPct"
                                    })}
                                </div>
                              </div>
                            )}
                          {backtest.entrySlopePct !== "none" &&
                            backtest.entrySlopePct !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {"Slope Percentile 1y is " +
                                    getLevel({
                                      backtest: backtest,
                                      indicator: "entrySlopePct"
                                    })}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="w-36">
                          {backtest.exitStopLoss !== "none" &&
                            backtest.exitStopLoss !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {getLevel({
                                    backtest: backtest,
                                    indicator: "exitStopLoss"
                                  }) + " Stop Loss"}
                                </div>
                              </div>
                            )}
                          {backtest.exitProfit !== "none" &&
                            backtest.exitProfit !== "" && (
                              <div className="w-full">
                                <div className="mb-0.5 mt-0.5 inline-block rounded-full bg-slate-100 px-3 py-2 text-xs dark:bg-zinc-700">
                                  {getLevel({
                                    backtest: backtest,
                                    indicator: "exitProfit"
                                  }) + " Profit Target"}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-row border-b dark:border-zinc-600 px-4 py-2 group-hover:bg-slate-50 dark:group-hover:bg-zinc-800">
                        {performanceColumns.map(col => (
                          <div
                            key={col.id}
                            className="mr-6 flex w-44 flex-row items-center"
                          >
                            {formatPerformanceMetric(col.id, backtest[col.id])}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
