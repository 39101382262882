export default function ShowLevel({ level }) {
  if (level == "low") {
    return (
      <div className="ml-2 rounded-full bg-emerald-600/10 px-2 py-0.5 text-xs text-emerald-600">
        LOW
      </div>
    )
  } else if (level == "moderate") {
    return (
      <div className="ml-2 rounded-full bg-yellow-600/10 px-2 py-0.5 text-xs text-yellow-600">
        MODERATE
      </div>
    )
  } else if (level == "high") {
    return (
      <div className="ml-2 rounded-full bg-red-600/10 px-2 py-0.5 text-xs text-red-600">
        HIGH
      </div>
    )
  }
}
