import { useAtom } from "jotai"
import { themeAtom } from "/lib/store"

export default function MonthlyReturns({ data }) {
  const [theme, setTheme] = useAtom(themeAtom)

  return (
    <div className="scrollbar w-full overflow-x-auto">
      <div className="min-w-[58rem]">
        <div className="mt-4 flex flex-row items-center text-sm font-normal italic">
          <div className="mr-1.5 h-2 w-2 rounded-full bg-red-600/60"></div>
          <div>{"Very low return"}</div>
          <div className="ml-6 mr-1.5 h-2 w-2 rounded-full bg-red-200/60"></div>
          <div>{"Low return"}</div>
          <div className="ml-6 mr-1.5 h-2 w-2 rounded-full bg-emerald-200/60"></div>
          <div>{"High return"}</div>
          <div className="ml-6 mr-1.5 h-2 w-2 rounded-full bg-emerald-600/60"></div>
          <div>{"Very high return"}</div>
        </div>
        <div className="mt-4 flex flex-row items-center text-sm">
          <div className="w-14 flex-none rounded-full bg-slate-100/80 dark:bg-zinc-700 py-2 text-center font-semibold">
            Year
          </div>
          <div className="ml-2 grid flex-grow grid-cols-12 gap-1 rounded-full bg-slate-100/80 dark:bg-zinc-700">
            {[
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ].map(month => (
              <div key={month} className="py-2 text-center font-semibold">
                {month}
              </div>
            ))}
          </div>
          <div className="ml-2 grid w-80 grid-cols-4 gap-1 rounded-full bg-slate-100/80 dark:bg-zinc-700 px-2">
            <div className="py-2 text-center">Return</div>
            <div className="py-2 text-center">Sharpe</div>
            <div className="py-2 text-center">Sortino</div>
            <div className="py-2 text-center">Volatility</div>
          </div>
        </div>
        <div className="mb-6 mt-2">
          {data.map(year => (
            <div key={year.year} className="mt-1 flex flex-row text-sm">
              <div className="w-14 flex-none py-0.5 text-center font-semibold">
                {year.year}
              </div>
              <div className="ml-2 grid flex-grow grid-cols-12 gap-1">
                {[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ].map(month => (
                  <div
                    key={month}
                    className={
                      "rounded-full py-0.5 text-center " +
                      getColor(year[month], "light", theme)
                    }
                  >
                    {year[month] !== 0 ? year[month].toFixed(2) : ""}
                  </div>
                ))}
              </div>
              <div className="ml-2 grid w-80 grid-cols-4 gap-1 px-2">
                <div
                  className={
                    "rounded-full py-0.5 text-center " +
                    getColor(year.annReturn, "strong", theme)
                  }
                >
                  {year.annReturn?.toFixed(2)}
                </div>
                <div className="py-0.5 text-center">
                  {year.annSharpe?.toFixed(2)}
                </div>
                <div className="py-0.5 text-center">
                  {year.annSortino?.toFixed(2)}
                </div>
                <div className="py-0.5 text-center">
                  {year.annVolatility?.toFixed(2)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function getColor(num, tone, theme) {
  if (num < -4) {
    if (tone === "light") {
      return "bg-red-600/30"
    } else {
      return "bg-red-600/50"
    }
  } else if (num < -1) {
    if (tone === "light") {
      return "bg-red-200/30"
    } else {
      return "bg-red-200/50"
    }
  } else if (num < 1) {
    if (theme === "dark") {
      return "bg-zinc-800"
    } else {
      return "bg-white"
    }
  } else if (num < 4) {
    if (tone == "light") {
      return "bg-emerald-200/30"
    } else {
      return "bg-emerald-200/50"
    }
  } else {
    if (tone == "light") {
      return "bg-emerald-600/30"
    } else {
      return "bg-emerald-600/50"
    }
  }
}
