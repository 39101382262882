import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useUserBacktests({ userId, submitted }, options = {}) {
  return useQuery(
    ["userBacktests", userId, submitted],
    () => fetchUserBacktests({ userId, submitted }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: userId != null,
      refetchIntervalInBackground: true,
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
      ...options
    }
  )
}

const fetchUserBacktests = async ({ userId, submitted }) => {
  const { data } = await axios.get("/api/orats/backtests/custom/performance", {
    params: { userId }
  })
  if (data?.length > 0) {
    data.sort((a, b) => {
      return new Date(b.createdDate) - new Date(a.createdDate)
    })
  }
  for (let i = 0; i < data?.length; i++) {
    data[i].status = "saved"
  }
  for (let i = 0; i < submitted?.length; i++) {
    let id = submitted[i].id
    let ticker = submitted[i].ticker
    let strategy = submitted[i].strategy
    let found = false
    for (let j = 0; j < data?.length; j++) {
      if (data[j].id == id) {
        found = true
      }
    }
    if (!found) {
      const { data: statusData } = await axios.get(
        "/api/orats/backtests/custom/status",
        {
          params: { id }
        }
      )
      data.unshift({ id, status: statusData.status, ticker, strategy })
    }
  }
  return data
}
