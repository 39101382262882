import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useAllBacktests({
  ticker,
  strategy,
  currentEnvironment,
  filters,
  rank,
  rankDir
}) {
  return useQuery(
    [
      "allBacktests",
      ticker,
      strategy,
      currentEnvironment,
      filters,
      rank,
      rankDir
    ],
    () =>
      fetchAllBacktests({
        ticker,
        strategy,
        currentEnvironment,
        filters,
        rank,
        rankDir
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled:
        ticker != null &&
        strategy != null &&
        currentEnvironment != null &&
        filters != null &&
        rank != null &&
        rankDir != null,
      staleTime: 1000 * 60 * 60,
      refetchInterval: 1000 * 60 * 60
    }
  )
}

const fetchAllBacktests = async ({
  ticker,
  strategy,
  currentEnvironment,
  filters,
  rank,
  rankDir
}) => {
  let payload = {
    ticker,
    strategy: strategy.id,
    currentEnvironment:
      currentEnvironment == "noCurrentEnvironment" ? null : currentEnvironment,
    filters,
    rank: rank.id,
    rankDir: rankDir.id
  }

  const url = "/api/orats/backtests/performance"
  const { data } = await axios.post(url, payload)
  return data.splice(0, 100)
}
