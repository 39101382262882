export function roundFloat(num, places) {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, places)) /
    Math.pow(10, places)
  )
}

export function roundWithLetter(num, includeDecimal = true) {
  if (num === null) {
    return ""
  } else {
    if (Math.abs(num) < 10) {
      return (Math.round((num + Number.EPSILON) * 100) / 100)?.toFixed(
        includeDecimal ? 2 : 0
      )
    } else if (Math.abs(num) < 100) {
      return (Math.round((num + Number.EPSILON) * 10) / 10)?.toFixed(
        includeDecimal ? 1 : 0
      )
    } else if (Math.abs(num) < 1000) {
      return Math.round(num + Number.EPSILON)?.toFixed(0)
    }
    let labels = ["k", "m", "b", "t"]
    for (let i = 0; i < labels.length; i++) {
      if (
        Math.abs(
          Math.round((num / Math.pow(1000, i + 1) + Number.EPSILON) * 100) / 100
        ) < 10
      ) {
        return (
          Math.round((num / Math.pow(1000, i + 1) + Number.EPSILON) * 100) /
            100 +
          labels[i]
        )
      } else if (
        Math.abs(
          Math.round((num / Math.pow(1000, i + 1) + Number.EPSILON) * 10) / 10
        ) < 100
      ) {
        return (
          Math.round((num / Math.pow(1000, i + 1) + Number.EPSILON) * 10) / 10 +
          labels[i]
        )
      } else if (Math.abs(Math.round(num / Math.pow(1000, i + 1))) < 1000) {
        return Math.round(num / Math.pow(1000, i + 1)) + labels[i]
      }
    }
  }
}

export function formatDate(exp) {
  if (exp) {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC"
    ]

    let arr = exp.split("-")
    let year = arr[0]
    let month = arr[1]
    let day = arr[2]

    year = year.slice(-2)
    month = monthNames[1 * month - 1]

    return day + "-" + month + "`" + year
  }
  return ""
}

export const strategyNames = [
  {
    name: "Long Call",
    id: "LongCall",
    category: "bullish",
    type: "single leg"
  },
  {
    name: "Short Put",
    id: "ShortPut",
    category: "bullish",
    type: "single leg"
  },
  {
    name: "Long Call Spread",
    id: "LongCallSpread",
    category: "bullish",
    type: "vertical spreads"
  },
  {
    name: "Short Put Spread",
    id: "ShortPutSpread",
    category: "bullish",
    type: "vertical spreads"
  },
  { name: "Put 1x2", id: "Put1x2", category: "bullish", type: "ratio spreads" },
  {
    name: "Risk Reversal",
    id: "RiskReversal",
    category: "bullish",
    type: "miscellaneous"
  },
  {
    name: "1x2 Call Backspread",
    id: "1x2CallBackspread",
    category: "bullish",
    type: "ratio spreads"
  },
  {
    name: "Short Call",
    id: "ShortCall",
    category: "bearish",
    type: "single leg"
  },
  { name: "Long Put", id: "LongPut", category: "bearish", type: "single leg" },
  {
    name: "Short Call Spread",
    id: "ShortCallSpread",
    category: "bearish",
    type: "vertical spreads"
  },
  {
    name: "Long Put Spread",
    id: "LongPutSpread",
    category: "bearish",
    type: "vertical spreads"
  },
  {
    name: "Call 1x2",
    id: "Call1x2",
    category: "bearish",
    type: "ratio spreads"
  },
  {
    name: "Short Risk Reversal",
    id: "ShortRiskReversal",
    category: "bearish",
    type: "miscellaneous"
  },
  {
    name: "1x2 Put Backspread",
    id: "1x2PutBackspread",
    category: "bearish",
    type: "ratio spreads"
  },
  {
    name: "Long Call Calendar",
    id: "LongCallCalendar",
    category: "neutral",
    type: "calendars"
  },
  {
    name: "Long Put Calendar",
    id: "LongPutCalendar",
    category: "neutral",
    type: "calendars"
  },
  {
    name: "Short Call Calendar",
    id: "ShortCallCalendar",
    category: "neutral",
    type: "calendars"
  },
  {
    name: "Short Put Calendar",
    id: "ShortPutCalendar",
    category: "neutral",
    type: "calendars"
  },
  {
    name: "Long Straddle",
    id: "LongStraddle",
    category: "neutral",
    type: "straddles"
  },
  {
    name: "Short Straddle",
    id: "ShortStraddle",
    category: "neutral",
    type: "straddles"
  },
  {
    name: "Long Strangle",
    id: "LongStrangle",
    category: "neutral",
    type: "straddles"
  },
  {
    name: "Short Strangle",
    id: "ShortStrangle",
    category: "neutral",
    type: "straddles"
  },
  {
    name: "Iron Condor",
    id: "IronCondor",
    category: "neutral",
    type: "miscellaneous"
  },
  {
    name: "Long Call Butterfly",
    id: "LongCallButterfly",
    category: "neutral",
    type: "butterflies"
  },
  {
    name: "Long Put Butterfly",
    id: "LongPutButterfly",
    category: "neutral",
    type: "butterflies"
  },
  {
    name: "Short Call Butterfly",
    id: "ShortCallButterfly",
    category: "neutral",
    type: "butterflies"
  },
  {
    name: "Short Put Butterfly",
    id: "ShortPutButterfly",
    category: "neutral",
    type: "butterflies"
  },
  {
    name: "Iron Butterfly",
    id: "IronButterfly",
    category: "neutral",
    type: "miscellaneous"
  }
]

export const containsAll = (sourceArray, toFindArray) => {
  return toFindArray.every(val => sourceArray.includes(val))
}
