import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleInfo,
  faChartLine,
  faSparkles,
  faWarning,
  faDollarSign
} from "@fortawesome/pro-solid-svg-icons"
import Tooltip from "./Tooltip"
import { performanceColumns, formatPerformanceMetric } from "./Shared.js"

export default function PerformanceMetrics({ data }) {
  return (
    <div className="mt-5">
      {categories.map(category => (
        <div key={category.id}>
          <div className={"flex flex-row items-center " + category.color}>
            <FontAwesomeIcon className="text-lg" icon={category.icon} />
            <div className="ml-2 text-lg font-semibold">{category.name}</div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pb-7 pr-2 pt-2 xl:grid-cols-5">
            {performanceColumns.map(
              metric =>
                metric.category === category.id && (
                  <div
                    key={metric.id}
                    className="rounded-lg bg-slate-100/80 dark:bg-zinc-700 px-4 py-3"
                  >
                    <div className="flex flex-row items-center">
                      <div className="text-sm text-slate-500 dark:text-zinc-300">
                        {metric.hasOwnProperty("shorthand")
                          ? metric.shorthand
                          : metric.name}
                      </div>
                      {metric.description && (
                        <Tooltip
                          content={
                            <div className="z-50 w-80 rounded-md bg-slate-900 dark:bg-white dark:text-black p-3 text-center text-xs font-normal italic text-white shadow-md">
                              {metric.description}
                            </div>
                          }
                        >
                          <FontAwesomeIcon
                            className="ml-1.5 cursor-pointer text-sm text-slate-400 transition hover:text-slate-500 dark:text-zinc-400 dark:hover:text-zinc-200"
                            icon={faCircleInfo}
                            aria-hidden="true"
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div className="mt-0.5 text-xl font-semibold">
                      {formatPerformanceMetric(metric.id, data[metric.id])}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const categories = [
  {
    id: "return",
    icon: faChartLine,
    name: "Return",
    color: "text-emerald-600"
  },
  {
    id: "risk",
    icon: faWarning,
    name: "Risk",
    color: "text-orange-600"
  },
  {
    id: "pl",
    icon: faDollarSign,
    name: "Profit & Loss",
    color: "text-blue-600"
  },
  {
    id: "other",
    icon: faSparkles,
    name: "Other",
    color: "text-slate-600"
  }
]
