import { useQuery } from "react-query"
import axios from "@/lib/axios"

export default function useBacktestReturns({ id, custom }) {
  return useQuery(
    ["backtestReturns", id, custom],
    () =>
      fetchBacktestReturns({
        id,
        custom
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )
}

const fetchBacktestReturns = async ({ id, custom }) => {
  if (custom) {
    const { data } = await axios.get("/api/orats/backtests/custom/results", {
      params: { id }
    })
    return data
  } else {
    const { data } = await axios.get("/api/orats/backtests/results", {
      params: { id }
    })
    return data
  }
}
